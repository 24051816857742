import _ from 'lodash'
import React, { Component } from 'react';
import { Dimmer, Loader, Grid, Table, Pagination, Button, Confirm } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Heading from '../Components/Heading';
import { DQNameToValue, DQValueToName } from '../helpers'

const ITEMS_PER_PAGE = 20;

class Games extends Component {
    constructor(props) {
        super(props)

        this.state = {
            column                   : null,
            data                     : [],
            totalGames               : 0,
            activePage               : 1,
            direction                : null,
            isLoading                : true,
            filter                   : '',
            gameIdValue              : '',
            vendorIdValue            : '',
            liveValue                : false,
            aiClickerValue           : false,
            isSearchLoading          : false,
            initialSearchResults     : [],
            filteredSearchResults    : [],
            isOpenConfirmDelete      : false,
            DELETE_game_id           : null,
            DELETE_vendor_id         : null,
            DELETE_data_quality      : null,
            deletingExtractions      : []
        }

        toast.configure({
            autoClose: 8000,
            position: toast.POSITION.BOTTOM_RIGHT
        })
    }

    async componentDidMount() {
        const vendorId = (new URLSearchParams(window.location.search)).get('vendor_id')
        const gameId = (new URLSearchParams(window.location.search)).get('game_id')
        const { column, direction } = this.state;

        const res = await this.fetchGames(0, column, direction, {'vendor_id': vendorId, 'game_id': gameId});
        this.setState({
            data: res.games,
            totalGames: res.totalGames,
            isLoading: false,
            gameIdValue: gameId ? gameId : '',
            vendorIdValue: vendorId ? vendorId : ''
        });
    }

    async fetchGames(startIdx, filter) {
        try {
            let url = `/api/admin/games_page?start=${startIdx}&limit=${ITEMS_PER_PAGE}`;
            if (filter) {
                Object.entries(filter).map(([k,v]) => {
                    if (v) url += `&${k}=${v}`
                    return null
                })
            }

            const response = await fetch(url, {
                accept: 'application/json',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('apikey')}`
                }
            });
            if (!response.ok) {
                throw Error(response.statusText);
            }
            const res = await response.json();
            res.games.forEach((game) => game.isDeleting = false);
            return res;
        } catch (e) {
            console.error(e);
            return { games: [], totalGames: 0 };
        }
    }

    handlePageChange = async (evt, { activePage }) => {
        this.setState({
            isLoading: true,
        });
        const { column, direction } = this.state;
        const startIdx = (activePage - 1) * ITEMS_PER_PAGE;
        const res = await this.fetchGames(startIdx, direction, column);
        this.setState({
            activePage,
            data: res.games,
            totalGames: res.totalGames,
            isLoading: false,
        });
    }

    // Leaving this here since we might add sorting again when we have filtering.
    /*handleSort = (clickedColumn) => async () => {
        this.setState({
            isLoading: true,
        });
        const { column, direction } = this.state;
        let sortOrder = 'ascending'
        if (column === clickedColumn) {
            sortOrder = direction === 'ascending' ? 'descending' : 'ascending';
        }
        const sortedGames = await this.fetchGames(0, ITEMS_PER_PAGE, sortOrder, clickedColumn);
        this.setState({
            activePage: 1,
            column: clickedColumn,
            direction: sortOrder,
            data: sortedGames.games,
            totalGames: sortedGames.totalGames,
            isLoading: false,
        });
    }*/

    handleResultSelect = (e, { result }) => this.setState({
        filter: result.title
    })

    handleSearchChange = (event) => {
        this.setState({
            isSearchLoading: true,
            filter: event.target.value
        }, function() {
            if (this.state.filter.length < 1) {
                return this.setState({
                    isSearchLoading: false,
                    filteredSearchResults: []
                })
            }

            const re = new RegExp(_.escapeRegExp(this.state.filter), 'i')
            const isMatch = (result) => re.test(result.title)

            const filteredResults = _.reduce(
                formatSearchResults(this.state.data),
                (memo, data, name) => {
                  const results = _.filter(data.results, isMatch)

                  if (results.length) memo[name] = { name, results }

                  console.log(memo)
                  return memo
                },
                {},
            )

            this.setState({
                isSearchLoading: false,
                filteredSearchResults: filteredResults
            })
        })
    }

    handleClickDeleteButton = (e, { game_id, vendor_id, data_quality}) => {
        let {data} = this.state
        let deletingExtractions = []
        const extractions = _.filter(data, {VendorID: vendor_id, GameID: game_id, DataQuality: data_quality})
        extractions.forEach(ext => {
            deletingExtractions.push(ext.ExtractionVersion)
        })
        data.forEach((game, index) => {
            if (game.GameID === game_id
                && game.VendorID === vendor_id
                && game.DataQuality === data_quality) {
                data[index].isDeleting = true
            }
        })
        data_quality = DQNameToValue(data_quality)
        this.setState({
            isOpenConfirmDelete: true,
            DELETE_game_id: game_id,
            DELETE_vendor_id: vendor_id,
            DELETE_data_quality: data_quality,
            deletingExtractions: deletingExtractions,
            data: data
        })
    }

    handleCancelDelete = (e) => {
        let {data} = this.state
        data.forEach((game, index) => {
            if (game.GameID === this.state.DELETE_game_id
                && game.VendorID === this.state.DELETE_vendor_id
                && game.DataQuality === DQValueToName(this.state.DELETE_data_quality)) {
                data[index].isDeleting = false   
            }
        })
        this.setState({
            data: data,
            DELETE_vendor_id   : null,
            DELETE_game_id     : null,
            DELETE_data_quality: null,
            deletingExtractions: [],
            isOpenConfirmDelete: false
        })
    }

    handleConfirmDelete = (e) => {
        let {data} = this.state
        this.setState({
            isOpenConfirmDelete: false,
        }, function() {
            fetch(`/api/admin/game/${this.state.DELETE_vendor_id}/${this.state.DELETE_game_id}/${this.state.DELETE_data_quality}`, {
                method: 'DELETE',
                accept: 'application/json',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('apikey')}`,
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                if (response.status !== 200) {
                    throw new Error('Error deleting game')
                }
                return response
            })
            .then(response => response.json())
            .then(response => {
                if (response.status === "Game jobs are currently processing") {
                    data.forEach((game, index) => {
                        if (game.GameID === this.state.DELETE_game_id
                            && game.VendorID === this.state.DELETE_vendor_id
                            && game.DataQuality === DQValueToName(this.state.DELETE_data_quality)) {
                                data[index].isDeleting = false
                        }
                    })
                    toast.info("Can't delete game while extracting")
                }
                else {
                    // Deleted
                    data.forEach((game, index) => {
                        if (game.GameID === this.state.DELETE_game_id
                            && game.VendorID === this.state.DELETE_vendor_id
                            && game.DataQuality === DQValueToName(this.state.DELETE_data_quality)) {
                            delete data[index]
                        }
                    })
                    toast.success(`Deleted ${this.state.DELETE_vendor_id}-${this.state.DELETE_game_id}-${this.state.DELETE_data_quality}`)
                }
                this.setState({
                    data: data,
                    DELETE_vendor_id   : null,
                    DELETE_game_id     : null,
                    DELETE_data_quality: null,
                    deletingExtractions: []
                })
            })
            .catch(error => {
                // Not deleted, reset isDeleting
                data.forEach((game, index) => {
                    if (game.GameID === this.state.DELETE_game_id
                        && game.VendorID === this.state.DELETE_vendor_id
                        && game.DataQuality === DQValueToName(this.state.DELETE_data_quality)) {
                        data[index].isDeleting = false
                    }
                })
                this.setState({
                    data: data,
                    DELETE_vendor_id   : null,
                    DELETE_game_id     : null,
                    DELETE_data_quality: null,
                    deletingExtractions: []
                })
                toast.error("Couldn't delete game")
            })
        })
    }

    handleGameIdChange = (event) => {
        this.setState({gameIdValue: event.target.value});
    }

    handleVendorIdChange = (event) => {
        this.setState({vendorIdValue: event.target.value});
    }

    handleLiveChange = (event, data) => {
        this.setState({liveValue: data.checked});
    }

    handleAiClickerChange = (event, data) => {
        this.setState({aiClickerValue: data.checked});
    }

    clickFilterButton = (event) => {
        const { gameIdValue, vendorIdValue } = this.state;
        if (gameIdValue && !vendorIdValue) {
            toast.error("Missing mandatory Vendor ID for Game ID search")
            return false;
        }

        this.setState({
            isSearchLoading: true
        }, async function() {
            const { column, direction, gameIdValue, vendorIdValue, liveValue, aiClickerValue } = this.state;
            /*if (gameIdValue.length < 1 && vendorIdValue.length < 1 && !liveValue) {
                return this.setState({
                    isSearchLoading: false
                })
            };*/

            const params = { game_id: gameIdValue, vendor_id: vendorIdValue, live: liveValue, ai_clicker: aiClickerValue }
            const res = await this.fetchGames(0, ITEMS_PER_PAGE, column, direction, params)

            this.setState({
                data: res.games,
                totalGames: res.totalGames,
                isSearchLoading: false
            })

            if (gameIdValue) {
                window.history.replaceState(null, document.title, `/games?game_id=${gameIdValue}&vendor_id=${vendorIdValue}`)
            }
        })
    }

    render() {
        return (
            <>
                <Dimmer inverted className={this.state.isLoading ? 'active' : ''}>
                    <Loader size='large' content='Loading'></Loader>
                </Dimmer>

                <Confirm
                    open={this.state.isOpenConfirmDelete}
                    onCancel={this.handleCancelDelete}
                    onConfirm={this.handleConfirmDelete}
                    size='small'
                    confirmButton='Delete'
                    header='Deleting game data'
                    content={`All raw data and extracted versions (${this.state.deletingExtractions.sort().join(', ')}) for the game will be deleted.`}
                />

                <Grid padded>
                    {/* <Grid.Row columns={1}>
                        <GridColumn width={4}>
                            <Search
                                category
                                fluid={true}
                                input={{fluid: true}}
                                loading={this.state.isSearchLoading}
                                onResultSelect={this.handleResultSelect}
                                onSearchChange={this.handleSearchChange}
                                value={this.state.filter}
                                results={this.state.filteredSearchResults}
                            />
                        </GridColumn>
                    </Grid.Row> */}

                    <Grid.Row columns={1}>
                        <Grid.Column>
                            {/* <Form className={'filter-form'} onSubmit={this.clickFilterButton}>
                                <Form.Group>
                                    <Form.Field  width={2}>
                                        <input placeholder='Vendor ID' value={this.state.vendorIdValue} onChange={this.handleVendorIdChange} />
                                    </Form.Field>
                                    <Form.Field width={2}>
                                        <input placeholder='Game ID' value={this.state.gameIdValue} onChange={this.handleGameIdChange} />
                                    </Form.Field>
                                    <Form.Field  width={2}>
                                        <Checkbox label='Live games' checked={this.state.liveValue} onChange={this.handleLiveChange} />
                                    </Form.Field>
                                    <Form.Field  width={2}>
                                        <Checkbox label='AI clicker' checked={this.state.aiClickerValue} onChange={this.handleAiClickerChange} />
                                    </Form.Field>
                                    <Form.Field  width={1}>
                                        <Button type='submit' className={'cta'}>Filter</Button>
                                    </Form.Field>
                                </Form.Group>
                            </Form> */}
                            <Table singleLine>
                                <Table.Header>
                                    <Table.Row>
                                    <Table.HeaderCell>
                                            Vendor ID
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Game ID
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Home Team
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Away Team
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Date
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Data Quality
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Extraction Version
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Extraction Lib
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Status
                                        </Table.HeaderCell>
                                        {/* <Table.HeaderCell
                                        sorted={this.state.column === 'AIClicker' ? this.state.direction : null}
                                        onClick={this.handleSort('AIClicker')}>
                                            Clicker Flags
                                        </Table.HeaderCell> */}
                                        <Table.HeaderCell/>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {
                                        // this.state.data.map((game, index) => {
                                        //     const filter = this.state.filter.toLowerCase()
                                        //     if (filter === ''
                                        //     || String(game.VendorID).includes(filter)
                                        //     || String(game.GameID).includes(filter)
                                        //     || String(game.HomeTeamName.toLowerCase()).includes(filter)
                                        //     || String(game.AwayTeamName.toLowerCase()).includes(filter)
                                        //     || String(game.DataQuality).includes(filter)
                                        //     || String(game.ExtractionVersion).includes(filter)
                                        //     || String(game.Status.toLowerCase()).includes(filter)) {
                                        //         return <BoutRow game={game} key={index} handleClickDeleteButton={this.handleClickDeleteButton}/>
                                        //     }
                                        // })

                                        this.state.data.map((game, index) => {
                                            return <BoutRow game={game} key={index} handleClickDeleteButton={this.handleClickDeleteButton}/>
                                        })
                                    }
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={1}>
                        <Pagination
                            activePage={this.state.activePage}
                            totalPages={Math.ceil(this.state.totalGames / ITEMS_PER_PAGE)}
                            onPageChange={this.handlePageChange}
                            style={{ 'marginLeft': '15px' }}
                        />
                    </Grid.Row>
                </Grid>
            </>
        )
    }
}

function BoutRow(props) {
    const formatedDate = props.game.Date ? props.game.Date.slice(0, -3) : ''; // Remove seconds
    return (
        <React.Fragment>
            {
                <Table.Row error={props.game.Attempts > 0}>
                    <Table.Cell>{props.game.VendorID}</Table.Cell>
                    <Table.Cell>{props.game.GameID}</Table.Cell>
                    <Table.Cell>{props.game.HomeTeamName}</Table.Cell>
                    <Table.Cell>{props.game.AwayTeamName}</Table.Cell>
                    <Table.Cell>{formatedDate}</Table.Cell>
                    <Table.Cell>{props.game.DataQuality}</Table.Cell>
                    <Table.Cell>{props.game.ExtractionVersion}</Table.Cell>
                    <Table.Cell>{props.game.ExtractionLibraryVersion}</Table.Cell>
                    <Table.Cell>{props.game.Status} {props.game.Attempts > 0 ? `(${props.game.Attempts} attempts)` : ''}</Table.Cell>
                    {/* <Table.Cell>{(props.game.AIClicker === true) ? 'AI' : 'Manual'}</Table.Cell> */}
                    <Table.Cell>
                        {/* {(props.game.DataQuality === 'Observed' || props.game.DataQuality === 'Partial') && */}
                            <Button
                            disabled={(props.game.DataQuality === 'Observed' || props.game.DataQuality === 'Partial') ? false : true}
                            color={(props.game.DataQuality === 'Observed' || props.game.DataQuality === 'Partial') ? 'red' : 'grey'}
                            className={props.game.isDeleting === true ? 'delete loading': 'delete'}
                            content='Delete'
                            loading={props.game.isDeleting === true ? true : false}
                            game_id={props.game.GameID}
                            vendor_id={props.game.VendorID}
                            data_quality={props.game.DataQuality}
                            onClick={props.handleClickDeleteButton}/>
                        {/* } */}
                    </Table.Cell>
                </Table.Row>
            }
        </React.Fragment>
    )
}

function formatSearchResults(games) {
    let gamesValuesVendorID = new Set()
    let gamesValuesGameID = new Set()
    let gamesValuesHomeTeamName = new Set()
    let gamesValuesAwayTeamName = new Set()
    let gamesValuesDataQuality = new Set()
    let gamesValuesExtractionVersion = new Set()
    let gamesValuesStatus = new Set()

    games.forEach(game => {
        gamesValuesVendorID.add(String(game.VendorID))
        gamesValuesGameID.add(String(game.GameID))
        gamesValuesHomeTeamName.add(String(game.HomeTeamName))
        gamesValuesAwayTeamName.add(String(game.AwayTeamName))
        gamesValuesDataQuality.add(String(game.DataQuality))
        gamesValuesExtractionVersion.add(String(game.ExtractionVersion))
        gamesValuesStatus.add(String(game.Status))
    })

    let results = {
        VendorID: {
            name: "Vendor ID",
            results: Array.from(gamesValuesVendorID).map((vendorID, index) => {
                return {title: vendorID}
            })
        },
        GameID: {
            name: "Game ID",
            results: Array.from(gamesValuesGameID).map((gameID, index) => {
                return {title: gameID}
            })
        },
        HomeTeam: {
            name: "Home Team",
            results: Array.from(gamesValuesHomeTeamName).map((homeTeamName, index) => {
                return {title: homeTeamName}
            })
        },
        AwayTeam: {
            name: "Away Team",
            results: Array.from(gamesValuesAwayTeamName).map((awayTeamName, index) => {
                return {title: awayTeamName}
            })
        },
        DataQuality: {
            name: "Data Quality",
            results: Array.from(gamesValuesDataQuality).map((dataQuality, index) => {
                return {title: dataQuality}
            })
        },
        ExtractionVersion: {
            name: "Extraction Version",
            results: Array.from(gamesValuesExtractionVersion).map((extractionVersion, index) => {
                return {title: extractionVersion}
            })
        },
        Status: {
            name: "Status",
            results: Array.from(gamesValuesStatus).map((status, index) => {
                return {title: status}
            })
        }
    }

    return results
}

export default Games
