import React, { Component } from 'react';
import {
    Button,
    Form,
    Message,
    Dropdown,
    Modal,
    Checkbox
} from 'semantic-ui-react'

import _ from 'lodash'

import {
    // DateInput,
    // TimeInput,
    // DateTimeInput,
    DatesRangeInput
} from 'semantic-ui-calendar-react';
// import DropdownExample from './DropdownExample'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const AddPermission = (props) => {
    return (
        <>
        {/* <Message attached header='Permissions Group' content='Fill out the form below to add or edit permission groups' /> */}
        <Form autocomplete='off'  className={`attached fluid ${props.isLoading ? 'loading' : ''} ${props.warnings.length > 0 ? 'warning':''}`}>
            <Form.Group widths='equal'>
                <Form.Input error={props.error_name} fluid placeholder='Name' name='name' value={props.name} onChange={props.handleChange} />
            </Form.Group>
            <Form.Group>
                <Form.Field>
                    <Checkbox toggle label='Live' name='live' checked={props.live} onChange={props.handleCheckboxChange} />
                </Form.Field>
                <Form.Field>
                    <Checkbox toggle label='Observed' name='observed' checked={props.observed} onChange={props.handleCheckboxChange} />
                </Form.Field>
                <Form.Field>
                    <Checkbox toggle label='Partial' name='partial' checked={props.partial} onChange={props.handleCheckboxChange} />
                </Form.Field>
            </Form.Group>
            <Form.Field>
                <Checkbox toggle label='Officials' name='officials' checked={props.officials} onChange={props.handleCheckboxChange} />
            </Form.Field>
            <Form.Field>
                <Checkbox toggle label='Active' name='active' checked={props.active} onChange={props.handleCheckboxChange} />
            </Form.Field>
            <Form.Field>
                <DatesRangeInput
                    name="datesRange"
                    error={props.error_date}
                    placeholder="From - To"
                    value={props.datesRange}
                    iconPosition="left"
                    onChange={props.handleDateChange}
                    dateFormat="YYYY-MM-DD"
                    closable
                    animation='none'
                />
            </Form.Field>
            <Form.Field>
                <Dropdown
                    placeholder='Extraction Versions'
                    error={props.error_ev}
                    fluid
                    multiple
                    clearable
                    search
                    selection
                    options={props.availableExtractions}
                    value={props.selected_EVs}
                    onChange={props.handleEVDropDownChange}
                />
            </Form.Field>
            <Form.Field>
                <Dropdown
                    placeholder='Outputs'
                    error={props.error_items}
                    fluid
                    multiple
                    clearable
                    search
                    selection
                    options={props.tagOptions}
                    value={props.dropDownValues}
                    onChange={props.handleDropDownChange}
                />
            </Form.Field>
            <Form.Field>
                <Dropdown
                    placeholder='Users'
                    error={props.error_users}
                    fluid
                    multiple
                    clearable
                    search
                    selection
                    options={props.users}
                    value={props.dropDownUsersValues}
                    onChange={props.handleUsersDropDownChange}
                />
            </Form.Field>
            {
                props.gameFilter.map((group, index) => {
                    let button
                    if (index === 0) button = <Button content='Add' onClick={props.handleAddGameFilter} />

                    return (<Form.Group>
                        <Form.Input placeholder='VendorID' name='VendorID' value={group.VendorID} index={index} onChange={props.handleChangeGameFilter}/>
                        <Form.Input placeholder='Leagues' name='leagues' value={group.leagues} index={index} onChange={props.handleChangeGameFilter}/>
                        <Form.Input placeholder='Seasons' name='seasons' value={group.seasons} index={index} onChange={props.handleChangeGameFilter}/>
                        <Form.Input placeholder='Teams' name='teams' value={group.teams} index={index} onChange={props.handleChangeGameFilter}/>
                        <Form.Input placeholder='Games' name='games' value={group.games} index={index} onChange={props.handleChangeGameFilter}/>
                        {button}
                    </Form.Group>)
                })
            }

            <Message warning header='Could you check something' list={props.warnings} />

            {props.edit === true ? (
            <Button
                className={props.isSending === true ? 'loading': ''}
                small
                color={props.edit === true ? 'green' : 'blue'}
                onClick={props.edit === true ? props.handleUpdate : props.handleSubmit}
                content={props.edit === true ? 'Update' : 'Submit'}
            />
            ) : (
            <Button.Group>
                <Button
                    className={props.isSending === true ? 'loading': ''}
                    small
                    color={props.edit === true ? 'green' : 'blue'}
                    onClick={props.edit === true ? props.handleUpdate : props.handleSubmit}
                    content={props.edit === true ? 'Update' : 'Submit'}
                />
                <Button.Or />
                <Button small color='red' onClick={props.handleFormReset} content='Reset'/>
            </Button.Group>
            )}



        </Form>
        </>
    )
}

const defaultState = {
    id                   : null,
    isLoading            : false,
    isSending            : false,
    warnings             : [],
    edit                 : false,

    name                 : '',
    live                 : false,
    observed             : false,
    partial              : false,
    active               : false,
    officials            : false,
    datesRange           : '',
    gameFilter           : [{VendorID: '', leagues: '', seasons: '', teams: '', games: ''}],
    dropDownValues       : [], // Selected permission items
    dropDownUsersValues  : [], // Selected users, we will need to read this from props when editing
    Selected_EVs         : [], // Selected EV's

    error_name : false,
    error_date : false,
    error_ev   : false,
    error_items: false,
    error_users: false,
}

class AddPermissionNew extends Component {
    constructor(props) {
        super(props)

        this.state = Object.assign({}, defaultState, _.pickBy(this.props, (value, key) => key in defaultState))

        this.handleChange              = this.handleChange.bind(this)
        this.handleUsersDropDownChange = this.handleUsersDropDownChange.bind(this)
        this.handleDateChange          = this.handleDateChange.bind(this)
        this.handleAddGameFilter       = this.handleAddGameFilter.bind(this)
        this.handleCheckboxChange      = this.handleCheckboxChange.bind(this)
        this.handleEVDropDownChange    = this.handleEVDropDownChange.bind(this)
        this.handleDropDownChange      = this.handleDropDownChange.bind(this)
        this.handleFormReset           = this.handleFormReset.bind(this)
        this.handleChangeGameFilter    = this.handleChangeGameFilter.bind(this)
        this.handleSubmit              = this.handleSubmit.bind(this)
        this.handleUpdate              = this.handleUpdate.bind(this)

        toast.configure({
            autoClose: 8000,
            position: toast.POSITION.BOTTOM_RIGHT
        })
    }

    handleFormReset() {
        this.setState(defaultState)
    }

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value})
        // this.setState({name: e.target.value})
    }

    handleUsersDropDownChange(e, { name, value }) {
        // alert(JSON.stringify(value))
        this.setState({dropDownUsersValues: value})
    }

    handleDateChange(e, {name, value}) {
        this.setState({datesRange: value})
    }

    handleCheckboxChange(e, { checked, name }) {
        const state = Object.assign({}, this.state)
        state[name] = checked
        this.setState(state)
    }

    handleAddGameFilter(e) {
        e.preventDefault()
        const state = Object.assign({}, this.state)
        state.gameFilter.push({ VendorID: '', leagues: '', seasons: '', teams: '', games: '' })
        this.setState(state)
    }

    handleEVDropDownChange(e, { name, value }) {
        // alert(JSON.stringify(value))
        this.setState({Selected_EVs: value})
    }

    handleDropDownChange(e, { name, value }) {
        // alert(JSON.stringify(value))
        this.setState({dropDownValues: value})
    }

    handleChangeGameFilter(e, {index}) {
        const state = Object.assign({}, this.state)
        state.gameFilter[index][e.target.name] = e.target.value
        this.setState(state)
    }


    validateForm(callback) {
        const warnings = []
        let name       = false
        let date       = false
        let ev         = false
        let items      = false
        let users      = false
        var games      = false

        if (this.state.name.length === 0)
        {
            warnings.push('Name is required')
            name = true
        }

        if (this.state.datesRange.length === 0)
        {
            warnings.push('Date range is required')
            date = true
        }

        if (this.state.Selected_EVs.length === 0)
        {
            warnings.push('Select extraction versions')
            ev = true
        }

        if (this.state.dropDownValues.length === 0)
        {
            warnings.push('Select outputs')
            items = true
        }

        if (this.state.dropDownUsersValues.length === 0)
        {
            warnings.push('Select users')
            users = true
        }

        this.state.gameFilter.forEach(object => {
            if ('VendorID' in object && isNaN(parseInt(object.VendorID)) === false)
            {
                let obj = {
                    VendorID: parseInt(object.VendorID)
                }

                if ('leagues' in object && object.leagues !== "") obj.Leagues = object.leagues.split(',').map(league => parseInt(league))
                if ('seasons' in object && object.seasons !== "") obj.Seasons = object.seasons.split(',').map(season => parseInt(season))
                if ('teams' in object && object.teams !== "") obj.Teams = object.teams.split(',').map(team => parseInt(team))
                if ('games' in object && object.games !== "") obj.Games = object.games.split(',').map(game => parseInt(game))

                games = true
            }
        })

        if (games === false) warnings.push('Add at least 1 game permission')


        this.setState({
            warnings   : warnings,
            error_name : name,
            error_date : date,
            error_ev   : ev,
            error_items: items,
            error_users: users
        }, () => callback(warnings.length === 0 ? true : false))
    }

    formData() {
        const dateSplit = this.state.datesRange.split(" - ")
        let permissions = []
        this.state.gameFilter.forEach(object => {
            if ('VendorID' in object && isNaN(parseInt(object.VendorID)) === false)
            {
                let obj = {
                    VendorID: parseInt(object.VendorID)
                }

                if ('leagues' in object && object.leagues !== "") obj.Leagues = object.leagues.split(',').map(league => parseInt(league))
                if ('seasons' in object && object.seasons !== "") obj.Seasons = object.seasons.split(',').map(season => parseInt(season))
                if ('teams' in object && object.teams !== "") obj.Teams = object.teams.split(',').map(team => parseInt(team))
                if ('games' in object && object.games !== "") obj.Games = object.games.split(',').map(game => parseInt(game))

                permissions.push(obj)
            }
        })

        const data = {
            Name     : this.state.name,
            DataType : [],
            Active   : this.state.active,
            Officials: this.state.officials,
            Users    : {
                Groups: [],
                Individuals: this.state.dropDownUsersValues
            },
            Start             : dateSplit[0],
            Expire            : dateSplit[1],
            ExtractionVersions: this.state.Selected_EVs,
            Outputs           : this.state.dropDownValues,
            Permissions       : permissions
        }

        // Push to DataType array
        if (this.state.live === true) data.DataType.push('Live')
        if (this.state.observed === true) data.DataType.push('Observed')
        if (this.state.partial === true) data.DataType.push('Partial')

        return JSON.stringify(data)
    }


    handleSubmit(e) {
        e.preventDefault()

        this.validateForm((valid) => {
            if (valid === true)
            {
                this.setState({isSending: true}, () => {
                    fetch('/api/admin/permission/create', {
                        method: 'POST',
                        accept: 'application/json',
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('apikey')}`,
                            'Content-Type': 'application/json'
                        },
                        body: this.formData()
                    })
                    .then(response => {
                        if (!response.ok) throw new Error('Bad response')
                        return response
                    })
                    .then(response => response.json())
                    .then(res => {
                        // this.setState(defaultState)
                        this.props.addPermission(res)
                    })
                    .catch(error => {
                        console.log(error)
                        toast.error("Error creating permission group")
                        this.setState({isSending: false})
                    })
                })
            }
        })
    }

    handleUpdate(e) {
        e.preventDefault()

        this.validateForm((valid) => {
            if (valid === true)
            {
                const data = this.formData()
                this.setState({isSending: true}, () => {
                    fetch(`/api/admin/permission/${this.state.id}`, {
                        method: 'PUT',
                        accept: 'application/json',
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('apikey')}`,
                            'Content-Type': 'application/json'
                        },
                        body: data
                    })
                    .then(response => {
                        if (!response.ok) throw new Error('Bad response')
                        return response
                    })
                    .then(response => response.json())
                    .then(res => {
                        // When in edit mode this is a controlled componenet using an updating key property which in turn re-renders
                        // this component, so we don't need to handle anything after this like isSending
                        this.props.updatePermission(this.state.id, JSON.parse(data))
                        // this.setState({isSending: false})
                    })
                    .catch(error => {
                        console.log(error)
                        toast.error("Error creating permission group")
                        this.setState({isSending: false})
                    })
                })
            }
        })
    }


    render() {
        return (<Modal size='large' trigger={<Button color={this.state.edit ? 'blue' : 'green'}>{this.state.edit ? 'Edit' : 'Create Group'}</Button>} closeOnDimmerClick={false} closeIcon>
            <Modal.Header>Permission Group</Modal.Header>
            <Modal.Content>
                {/* <Image wrapped size='medium' src='/images/avatar/large/rachel.png' /> */}
                {/* <Modal.Description> */}

                        <AddPermission
                            // Populate from props
                            users                = {this.props.users} // Available users
                            tagOptions           = {this.props.tagOptions} // Available permission items
                            availableExtractions = {this.props.availableExtractions} // Available EV's

                            // Errors
                            error_name  = {this.state.error_name}
                            error_date  = {this.state.error_date}
                            error_ev    = {this.state.error_ev}
                            error_items = {this.state.error_items}
                            error_users = {this.state.error_users}

                            // Options
                            edit      = {this.state.edit}
                            isSending = {this.state.isSending}
                            warnings  = {this.state.warnings}

                            isLoading = {this.props.isLoading}
                            // Data properties
                            // key                 = {Math.random()}
                            name                 = {this.state.name}
                            live                 = {this.state.live}
                            observed             = {this.state.observed}
                            partial              = {this.state.partial}
                            active               = {this.state.active}
                            officials            = {this.state.officials}
                            datesRange           = {this.state.datesRange}
                            gameFilter           = {this.state.gameFilter}
                            dropDownUsersValues  = {this.state.dropDownUsersValues} // Selected users
                            selected_EVs         = {this.state.Selected_EVs} // Selected EV's
                            dropDownValues       = {this.state.dropDownValues} // Selected permission items

                            // outputs              = {this.state.outputs}

                            // addPermission        = {this.props.addPermission} // I think this was replaced by handleSubmit


                            // Functions
                            handleCheckboxChange      = {this.handleCheckboxChange}
                            handleChange              = {this.handleChange} // Name change
                            handleUsersDropDownChange = {this.handleUsersDropDownChange} // User change
                            handleDateChange          = {this.handleDateChange}
                            handleAddGameFilter       = {this.handleAddGameFilter}
                            handleEVDropDownChange    = {this.handleEVDropDownChange} // EV change
                            handleDropDownChange      = {this.handleDropDownChange} // Permission items change
                            handleFormReset           = {this.handleFormReset}
                            handleChangeGameFilter    = {this.handleChangeGameFilter}
                            handleSubmit              = {this.handleSubmit}

                            handleUpdate              = {this.handleUpdate}
                        />


                {/* </Modal.Description> */}
            </Modal.Content>
            {/* <Modal.Actions><Button positive content='Close' labelPosition='left' /></Modal.Actions> */}
          </Modal>)
    }
}

export default AddPermissionNew
