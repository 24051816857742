import React, { Component } from 'react';
import { Grid, Dimmer, Loader, Button, Form, Message, Divider, Table, Modal, Checkbox } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import validator from 'validator'
import { isNull } from 'util';

toast.configure({position: toast.POSITION.BOTTOM_RIGHT, pauseOnFocusLoss: false})

function UserTable(props) {
    return (
        <Table compact selectable key='blue'>
            <Table.Header>
            <Table.Row>
                <Table.HeaderCell width={1}/>
                <Table.HeaderCell width={1}>User ID</Table.HeaderCell>
                <Table.HeaderCell width={3}>Email</Table.HeaderCell>
                <Table.HeaderCell width={7}>API Key</Table.HeaderCell>
                <Table.HeaderCell width={3}>Change Password</Table.HeaderCell>
                <Table.HeaderCell width={1}>Is Admin</Table.HeaderCell>
            </Table.Row>
            </Table.Header>
            <Table.Body>
            { props.users.map((user, index) => {
                return <UserRow key={user.user_id} toggleUserState={props.toggleUserState} user={user}/>
            }) }
            </Table.Body>
        </Table>
    )
}

class UserRow extends Component {
    constructor(props) {
        super(props)

        this.state = {
            valid: this.props.user.valid,
            apikey: null,
            fetching: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleClick = this.handleClick.bind(this)
    }

    handleChange(event) {
        // this.props.user.valid = false
        const state = !this.state.valid
        this.props.toggleUserState({_id: this.props.user._id, state: state})
        this.setState({
            valid: state
        })
    }

    copyToClipboard = (text) => {
        return navigator.permissions.query({name: "clipboard-write"})
        .then(result => {
            if (result.state === "denied") return Promise.reject('Denied')
        })
        .then(() => navigator.clipboard.writeText(text))
        .then(() => toast.info(`API key copied to clipboard - ${text.substring(0, 5)}...`))
        .catch(error => console.log(error))
    }

    handleClick(e) {
        // Prevent multiple clicks
        if (this.state.apikey === null)
        {
            this.setState({fetching: true})

            fetch(`/api/admin/users/${this.props.user._id}/apikey`, {
                headers: {'Authorization': `Bearer ${localStorage.getItem('apikey')}`}
            })
            .then(response => {
                if (response.status !== 200 ) throw new Error('Response not 200')
                return response.json()
            })
            .then(res => {
                this.copyToClipboard(res.apikey)
                this.setState({apikey: res.apikey, fetching: false}, () => {
                    setTimeout(() => {
                        this.setState({apikey: null})
                    }, 10000)
                })
            })
            .catch(error => {
                toast.error('Error getting API Key')
                this.setState({fetching: false})
            })
        }
    }

    render() {
        const isAdmin = this.props.user.admin === true
        return (
            <Table.Row index={'props.user.index'} className={this.state.valid ? 'positive' : 'negative'}>
                <Table.Cell collapsing>
                    <Checkbox slider defaultChecked={this.state.valid} onChange={this.handleChange} />
                </Table.Cell>
                <Table.Cell>{this.props.user.user_id}</Table.Cell>
                <Table.Cell>{this.props.user.email}</Table.Cell>
                {
                    document.queryCommandSupported('copy') &&
                    // <Table.Cell style={{cursor: isNull(this.state.apikey) ? 'pointer': ''}} onClick={this.handleClick}>{this.state.apikey !== null ? this.state.apikey : '******'}</Table.Cell>
                    <Table.Cell style={{paddingTop: 0, paddingBottom: 0}}>
                        <Button color={isNull(this.state.apikey) ? 'linkedin' : 'google plus'} size='mini' loading={this.state.fetching} onClick={this.handleClick}>{isNull(this.state.apikey) ? 'Copy' : 'Copied'}</Button>
                    </Table.Cell>
                }
                <Table.Cell>
                    {
                        isAdmin === false &&
                        <ModalChangePassword user={this.props.user}></ModalChangePassword>
                    }
                </Table.Cell>
                <Table.Cell style={{fontWeight: 'bold'}}>{this.props.user.admin === true ? 'Admin' : ''}</Table.Cell>
            </Table.Row>
        )
    }
}

const updatePassword = async (_id, newPassword) => {
    const response = await fetch(`/api/admin/users/${_id}/password`, {
        method: 'PUT',
        accept: 'application/json',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('apikey')}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ password: newPassword })
    });
    if (response.status !== 200) throw new Error(`Server returned ${response.status} (${response.statusText})`);
}

function ModalChangePassword(props) {
    const [open, setOpen] = React.useState(false);
    const [errors, setError] = React.useState(null);
    const [password, setPassword] = React.useState('');
    const [isLoading, setLoading] = React.useState(false);

    function inputChange(value) {
        setPassword(value);
    }

    async function submit(evt) {
        evt.preventDefault();
        setLoading(true);
        try {
            await updatePassword(props.user._id, password);
            setOpen(false);
        } catch (err) {
            setError(String(err));
        }
        setLoading(false);
    }

    return (
        <Modal
            size='small'
            onClose={() => setOpen(false)}
            onOpen={() => { setOpen(true); setError(null); setPassword('') }}
            open={open}
            trigger={<Button size='mini' color='linkedin'>New password</Button>}
        >
            <Modal.Header>Change password</Modal.Header>
            <Modal.Content>
                <Form onSubmit={submit} loading={isLoading} error={errors !== null}>
                    <Message
                        error
                        header='Could not update password'
                        content={errors}
                    />
                    <Form.Field>
                        <p>Enter new password for {props.user.email}</p>
                        <input autoComplete='new-password' type='password' placeholder='New password (at least 6 characters long)'
                            name='new_password' onChange={(evt) => inputChange(evt.target.value)} />
                    </Form.Field>
                    <Button size='small' color='grey' onClick={() => setOpen(false)}>Cancel</Button>
                    <Button size='small' color='linkedin' type='submit' disabled={password.length < 6}>Submit</Button>
                </Form>
            </Modal.Content>
        </Modal>
    );
}

const AddUser = (props) => {
    return (
        <div>
        <Message
            attached
            header='Add new user'
            content='Fill out the form below to add a new user'
        />
        <Form
            onSubmit={props.handleFormSubmit}
            className={`attached fluid segment ${props.warnings.length > 0 ? 'warning':''}`}
        >
            <Form.Group widths='equal'>
                <Form.Input fluid label='Email' autoComplete='off' type='text' placeholder='Email' name='form_email' value={props.email} onChange={props.handleFormChange}/>
                <Form.Input fluid label='Password' autoComplete='new-password' type='password' name='form_password' value={props.password} onChange={props.handleFormChange}/>
            </Form.Group>
            <Message
                warning
                header='Could you check something'
                list={props.warnings}
            />
            {/* <Message
                error
                header='Could you check something'
                list={[
                    'Invalid email address'
                ]}
            /> */}
            <Button size='small' color='blue' className={props.isSaving === true ? 'loading' : ''}>Submit</Button>
        </Form>
        </div>
    )
}

class Users extends Component {
    constructor(props) {
        super(props)

        this.state = {
            users: [],
            loading: true,

            form_email: '',
            form_password: '',
            form_isSaving: false,
            form_warnings: [],
            count: 0
        }

        this.toggleUserState = this.toggleUserState.bind(this)
        this.handleFormChange = this.handleFormChange.bind(this)
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
    }

    componentDidMount() {
      fetch('/api/admin/users', {
        accept: 'application/json',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('apikey')}`
        }
      })
      .then(response => response.json())
      .then(res => {
        console.log('LOADED')
        this.setState({
          users: res,
          loading: false
        })
      })
    }

    handleFormChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleFormSubmit(e) {
        e.preventDefault()
        // alert(`${this.state.form_email} ${this.state.form_password}`)

        const warnings = []
        // if (this.state.form_email.length == 0) warnings.push('Email not completed')
        if (validator.isEmail(this.state.form_email) === false) warnings.push('Email is not valid')
        // if (this.state.form_password.length == 0) warnings.push('Password not completed')
        if (this.state.form_password.length < 6) warnings.push('Password needs to be at least 6 characters')

        if (warnings.length > 0)
        {
            // We have user errors
            this.setState({form_warnings: warnings})
        }
        else
        {
            this.setState({form_isSaving: true}, () => {
                fetch('/api/admin/adduser', {
                    method: 'POST',
                    accept: 'application/json',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('apikey')}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        email: this.state.form_email,
                        password: this.state.form_password,
                    })
                })
                .then(response => response.json())
                .then(res => {
                    // console.log('LOADED')
                    // console.log(res)
                    // const users = Object.assign({}, this.state.users)
                    // console.log(users)
                    // users.push(res)
                    const users = this.state.users
                    users.push(res)
                    this.setState({
                        users: users,

                        form_email: '',
                        form_password: '',
                        form_isSaving: false,
                        form_warnings: []
                    })
                })
                // this.setState({
                //     form_email: '',
                //     form_password: '',
                //     form_isSaving: false
                // })
            })
        }
    }

    toggleUserState(user) {
        fetch(`/api/admin/users/${user._id}/toggle`, {
            method: 'PUT',
            accept: 'application/json',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('apikey')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                state: String(user.state)
            })
        })
        .then(response => {
            if (response.status !== 200) throw new Error('Wrong status code')
            return response.json()
        })
        .then(res => {
            const count = this.state.count
            this.setState({count: count + 1})
        })
        .catch(error => {
            toast.error('Error')
        })
    }

    render() {
        return (
        <>
        <Dimmer inverted className={this.state.loading ? 'active' : ''}>
            <Loader size='large' content='Loading'></Loader>
        </Dimmer>
        <Grid padded>
            <Grid.Row>
                <Grid.Column>
                    <AddUser
                        isSaving={this.state.form_isSaving}
                        email={this.state.form_email}
                        password={this.state.form_password}
                        warnings={this.state.form_warnings}
                        handleFormChange={this.handleFormChange}
                        handleFormSubmit={this.handleFormSubmit}
                    />

                    <Divider/>
                    <UserTable users={this.state.users} toggleUserState={this.toggleUserState}/>
                </Grid.Column>
            </Grid.Row>
            {/* <Grid.Row>
                <Grid.Column>
                    <UserTable users={this.state.users} toggleUserState={this.toggleUserState}/>
                </Grid.Column>
            </Grid.Row> */}
        </Grid>
        </>
        )
    }
}

export default Users
