import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Switch, NavLink, Link, Redirect, withRouter } from "react-router-dom";

import validator from 'validator'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import jwt_decode from 'jwt-decode';

import {
  Button,
  Container,
  Menu,
  Message,
  Form
} from "semantic-ui-react";

import Permissions from './permissions/Permissions'
import Status from './Status'
import Users from './users/Users'
import Games from './games/Games'
import Swagger from './Swagger'
import Dashboard from './dashboard/Dashboard'

import logo from './assets/logo.svg';

// import "semantic-ui-css/semantic.min.css";
import './styles/App.css';

class Login extends Component {
  state = {
    redirectToReferrer: false,
    email             : '',
    password          : '',
    warnings          : [],
    errors            : []
  }

  login = () => {
    const warnings = []
    if (validator.isEmail(this.state.email) === false) warnings.push('Invalid email address')
    if (this.state.password.length < 6) warnings.push('Password too short')

    if (warnings.length > 0)
    {
      this.setState({warnings: warnings})
    }
    else
    {
      fetch('/api/admin/login', {
        method: 'POST',
        accept: 'application/json',
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({email: this.state.email, password: this.state.password})
      })
      .then(response => {
        // console.log(response.status)
        if (response.status !== 200) return Promise.reject('Unauthorized')
        return response
      })
      .then(response => response.json())
      .then(res => {
        fakeAuth.authenticate(res.apikey, res.token, () => {
          this.setState(() => ({
            redirectToReferrer: true,
            email             : '',
            password          : '',
            warnings          : [],
            errors            : []
          }))
        })
      })
      .catch((e) => {
        this.setState(() => ({
          password          : '',
          warnings          : [],
          errors            : ['Unauthorized']
        }))
      })
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  render() {
    const { redirectToReferrer } = this.state

    if (redirectToReferrer === true) {
      return window.location.replace('/')
    }

    return (
      <Container style={{paddingTop: '10em'}}>
        <Message
            attached
            header='Login'
            // content='Fill out the form below to add or edit permission groups'
        />
        {/* <p>You must log in to view the page</p> */}
        <Form className={`attached fluid segment ${this.state.errors.length > 0 ? 'error':''} ${this.state.warnings.length > 0 ? 'warning':''}`}>
          <Form.Group widths='equal'>
            <Form.Input fluid type='text' placeholder='Email' name='email' value={this.state.email} onChange={this.handleChange}/>
            <Form.Input fluid type='password' placeholder='Password' name='password' value={this.state.password} onChange={this.handleChange}/>
          </Form.Group>

          <Message
            warning
            header='Could you check something'
            list={this.state.warnings}
          />

          <Message
            error
            header='Could not login'
            list={this.state.errors}
          />

          <Button onClick={this.login} content='Login' />
        </Form>
        {/* <button onClick={this.login}>Log in</button> */}
      </Container>
    )
  }
}

const NoMatch = ({ location }) => (
  <div>
    <h3>No match for <code>{location.pathname}</code></h3>
  </div>
)

const fakeAuth = {
  isAuthenticated: localStorage.getItem('apikey') ? true : false,
  authenticate(apikey, token, cb) {
    localStorage.setItem('jwt', token)
    localStorage.setItem('apikey', apikey)
    this.isAuthenticated = true
    setTimeout(cb, 100)
    // fetch('/api/admin/login', {
    //   accept: 'application/json',
    // })
    // .then(response => response.json())
    // .then(res => {
    //   console.log(res)
    //   localStorage.setItem('apikey', res.apikey)
    //   this.isAuthenticated = true
    //   cb()
    // })

  },
  signout(cb) {
    localStorage.clear()
    this.isAuthenticated = false
    fetch('/api/admin/logout') // Destroy session.
    setTimeout(cb, 100)
  }
}

function PrivateRoute({ component: Component, ...rest }) {
  let isAuthorized = false
  // Map token.permissions to rest.path to check access.
  if (fakeAuth.isAuthenticated) {
    let token = localStorage.getItem('jwt')
    try {
      token = jwt_decode(token)
    } catch (e) {
      fakeAuth.signout(() => {})
      console.log('Invalid token', e.message)
      return LoginRedirect(rest)
    }

    // @TODO Should we check expired token in another place?
    const timestamp = Math.floor(Date.now() / 1000)
    if (token.exp < timestamp) {
      fakeAuth.signout(() => {})
      toast.error('Your session has expired.')
      return LoginRedirect(rest)
    }

    const currentPath = rest.path.substring(1)
    if (rest.path === '/' && token.scopes.includes('dashboard')) {
      isAuthorized = true
    }
    else if (token.scopes.includes(currentPath)) {
      isAuthorized = true
    }
  }
  else {
    return LoginRedirect(rest)
  }

  if (isAuthorized === false) {
    toast.error('You are not authorized to view this page.')
    return null
  }

  return (
    <Route {...rest} render={(props) => (
      <Component {...props} />
    )} />
  )
}

const LoginRedirect = (rest) => {
  return <Route {...rest} render={(props) => (
    <Redirect to={{
      pathname: '/login',
      state: { from: props.location }
    }} />
  )} />
}

const AuthButton = withRouter(({ history }) => (
  fakeAuth.isAuthenticated ? (
    <Button
    size='small'
    onClick={() => {
      fakeAuth.signout(() => window.location.reload())
    }}>Sign out</Button>
  ) : (
    <p>You are not logged in.</p>
  )
))

class App extends Component {

  constructor(props)
  {
    super(props)
    this.state = {
      isLoggedIn: false,
      email     : '',
      password  : '',
      mobileMenu: false,
    }

    toast.configure({
      autoClose: 10000,
      position: toast.POSITION.BOTTOM_RIGHT
    })

    this.handleLogin = this.handleLogin.bind(this)
  }

  // componentWillMount()
  // {
  //   // localStorage.setItem('apikey', 'ADAjYjZkODk3Mjk2NDVjYTJjNTU2NTEyODFkODNkZTJmZWRjMmI1Mzg0ZDcxZDIyN2E4N2I2ZGM3NzgzM2MwNg==')
  //   if (localStorage.getItem('apikey')) fakeAuth.isAuthenticated = true
  //   // fakeAuth.isAuthenticated = true
  // }

  handleLogin = () => {
    this.setState({isLoggedIn: !this.state.isLoggedIn})
    // return <Redirect to='/users'/>
  }

  handleMenuToggle = () => {
    const current = this.state.mobileMenu;
    this.setState({ mobileMenu: !current });
  }

  checkLinkAccess = (link) => {
    let token = localStorage.getItem('jwt')
    try {
      token = jwt_decode(token)
      return ((link === '/' && token.scopes.includes('dashboard')) || token.scopes.includes(link)) ? true : false
    } catch (e) {
      console.log('Invalid token', e.message)
      return false
    }
  }

  render() {
    return (
      <Router>
        <Menu stackable inverted fixed='top' size='small' style={{zIndex: 1001}}>
          {/* <Container> */}
            <div className={`menu-icon ${this.state.mobileMenu ? 'open': ''}` } onClick={this.handleMenuToggle}>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <Menu.Item link header className={this.state.mobileMenu ? 'link-open': ''}>
              <Link to={'/'}>
                <img src={logo} className="App-logo" alt="logo" />
              </Link>
            </Menu.Item>
            {this.checkLinkAccess('games') &&
              <Menu.Item as={NavLink} to='/games' className={this.state.mobileMenu ? 'link-open': ''}>Games</Menu.Item>
            }
            {this.checkLinkAccess('users') &&
              <Menu.Item as={NavLink} to='/users' className={this.state.mobileMenu ? 'link-open': ''}>Users</Menu.Item>
            }
            {this.checkLinkAccess('permissions') &&
              <Menu.Item as={NavLink} to='/permissions' className={this.state.mobileMenu ? 'link-open': ''}>Permissions</Menu.Item>
            }
            <Menu.Item as={NavLink} to='/docs' className={this.state.mobileMenu ? 'link-open': ''}>Docs</Menu.Item>
            {this.checkLinkAccess('status') &&
              <Menu.Item as={NavLink} to='/status' className={this.state.mobileMenu ? 'link-open': ''}>Status</Menu.Item>
            }
            {this.checkLinkAccess('dash') &&
              <Menu.Item href='/dash' target='_blank' className={this.state.mobileMenu ? 'link-open': ''}>
                  Agenda MQ
              </Menu.Item>
            }
            <Menu.Item position='right' className={this.state.mobileMenu ? 'link-open': ''}>
              {localStorage.getItem('email')}
              <AuthButton />
            </Menu.Item>
          {/* </Container> */}
        </Menu>

        <div id="page" className="page-content">
        <Switch>
          <PrivateRoute path="/" exact component={Dashboard}/>
          <Route path="/login" component={Login} />
          <PrivateRoute path="/games" exact component={Games}/>
          <PrivateRoute path="/users" exact component={Users}/>
          <PrivateRoute path="/permissions" component={Permissions}/>
          <Route path="/docs" exact component={Swagger}/>
          <PrivateRoute path="/status" component={Status}/>
          <Route component={NoMatch}/>
        </Switch>


        </div>
      </Router>
    );
  }
}

export default App;
