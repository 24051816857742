import React, { Component } from "react"
import { Container, Grid, Icon, Ref } from 'semantic-ui-react'
import timer from './../assets/timer.svg'
import pitch from './../assets/pitch.svg'


export default class Game extends Component {
    constructor(props) {
        super(props)
        this.state = {
            lastFrame        : null,
            frameControl     : null,
            isOpen           : false,
            lastSkeletonFrame: null
        }
        this.frameRef = React.createRef()
        this.skeletonFrameRef = React.createRef()
        this.toggleRef = React.createRef()
    }

    componentDidMount() {
        const div = this.toggleRef.current
        div.className = `ui stackable divided three column grid game ${this.props.isOpen ? 'open' : 'collapsed'} ${this.props.type}`

        // Collapsible state
        const isOpen = this.props.isOpen ? true : false  
        
        this.setState({ isOpen: isOpen })
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (JSON.stringify(nextProps) === JSON.stringify(this.props)) ? false : true
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.updateGame(snapshot)
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        return {prevFrames: (prevProps.game.frames) ? prevProps.game.frames : null, skeletonFrames: (prevProps.streamData) ? prevProps.streamData.frame_number : null}
    }

    componentWillUnmount() {
        clearTimeout(this.frameControlTimer)
        clearTimeout(this.skeletonFrameControlTimer)
    }

    calculateGameTime = (game) => {
        const lastFrame        = game.frames,
              phase1StartFrame = game.phases.Phase.P1.StFC,
              phase1EndFrame   = game.phases.Phase.P1.EFC,
              phase2StartFrame = game.phases.Phase.P2.StFC,
              phase2EndFrame   = game.phases.Phase.P2.EFC,
              phase3StartFrame = game.phases.Phase.P3.StFC,
              phase3EndFrame   = game.phases.Phase.P3.EFC,
              phase4StartFrame = game.phases.Phase.P4.StFC,
              phase4EndFrame   = game.phases.Phase.P4.EFC,
              phase5StartFrame = game.phases.Phase.P5 ? game.phases.Phase.P5.StFC : 0,
              phase5EndFrame   = game.phases.Phase.P5 ? game.phases.Phase.P5.EFC: 0

        let time_frames = 0
        let phase = ''

        if (phase1StartFrame !== 0 && phase1EndFrame === 0) {
            // First half
            time_frames += lastFrame - phase1StartFrame
            phase = '1st'
        }
        else if (phase1EndFrame !== 0 && phase2StartFrame === 0) {
            // Half time
            time_frames += phase1EndFrame - phase1StartFrame
            phase = 'H.T'
        }
        else if (phase2StartFrame !== 0 && phase2EndFrame === 0) {
            // Second half
            const first_half_frames = 45 * 60 * game.FrameRate
            time_frames += (lastFrame - phase2StartFrame) + first_half_frames
            phase = '2nd'
        }
        else if (phase2EndFrame !== 0 && phase3StartFrame === 0) {
            // Full time or before extra time
            const first_half_frames = 45 * 60 * game.FrameRate
            time_frames += (phase2EndFrame - phase2StartFrame) + first_half_frames
            phase = 'F.T'
        }
        else if (phase3StartFrame !== 0 && phase3EndFrame === 0) {
            // First extra time
            const full_time_frames = 90 * 60 * game.FrameRate
            time_frames += (lastFrame - phase3StartFrame) + full_time_frames
            phase = '1st E.T'
        }
        else if (phase3EndFrame !== 0 && phase4StartFrame === 0) {
            // Extra time half time
            const full_time_frames = 90 * 60 * game.FrameRate
            time_frames += (phase3EndFrame - phase3StartFrame) + full_time_frames
            phase = 'E.T H.T'
        }
        else if (phase4StartFrame !== 0 && phase4EndFrame === 0) {
            // Second extra time
            const full_extra_time_frames = 105 * 60 * game.FrameRate
            time_frames += (lastFrame - phase4StartFrame) + full_extra_time_frames
            phase = '2nd E.T'
        }
        else if (phase4EndFrame !== 0 && phase5StartFrame === 0) {
            // Full time extra time or before penalty shootout
            const full_extra_time_frames = 105 * 60* game.FrameRate
            time_frames += (phase4EndFrame - phase4StartFrame) + full_extra_time_frames
            phase = 'F.T'
        }
        else if (phase5StartFrame !== 0 && phase5EndFrame === 0) {
            // Penalty kicks
            // Frames are only counted between Phase 1-4
            time_frames += 120 * 60 * game.FrameRate
            phase = 'Pen.'
        } else if (phase5EndFrame !== 0){
            // Frames are only counted between Phase 1-4
            time_frames += 120 * 60 * game.FrameRate
            phase = 'F.T'
        }
        // No 5th phase in Meta collection.

        time_frames = time_frames / game.FrameRate
        let m = Math.floor(time_frames / 60).toString().padStart(2,'0')
        const s = Math.floor(time_frames % 60).toString().padStart(2,'0')
        let gameTime = `${m}:${s}`

        if (isNaN(m) || isNaN(s) || time_frames < 0) {
            gameTime = '00:00'
        }

        return {gameTime: gameTime, phase: phase}
    }

    async updateGame(data) {
        clearTimeout(this.frameControlTimer)

        const game = this.props.game
        if (data.prevFrames && data.prevFrames < game.frames) {
            const div = this.frameRef.current
            div.className = 'data-holder receiving'
            this.setState({
                lastFrame : game.frames,
            })
            this.frameControlTimer = setTimeout(() => this.frameControl(game.frames), 3000)
        }

        if (game.gen6) {
            const streamData = this.props.streamData
            if (data.skeletonFrames && data.skeletonFrames < streamData.frame_number) {
                const div = this.skeletonFrameRef.current
                div.className = 'skeleton-frames data-holder receiving'
                this.setState({
                    lastSkeletonFrame : streamData.frame_number,
                })
            }
            else if (streamData !== undefined) {
                this.skeletonFrameControlTimer = setTimeout(() => this.skeletonFrameControl(streamData.frame_number), 3000)
            }
        }

    }

    frameControl(current) {
        if (this.state.lastFrame === current) {
            const div = this.frameRef.current
            div.className = 'data-holder stopped'
        }
    }

    skeletonFrameControl(current) {
        clearTimeout(this.skeletonFrameControlTimer)
        if (this.state.lastSkeletonFrame === current || current === null) {
            const div = this.skeletonFrameRef.current
            div.className = 'skeleton-frames data-holder stopped'
        }
    }

    toggleGame = () => {
        const isOpen = this.state.isOpen
        const div = this.toggleRef.current

        div.className = `ui stackable divided three column grid game ${isOpen ? 'collapsed' : 'open'} ${this.props.type}`

        this.setState({
            isOpen: !isOpen
        })
    }

    render() {
        const game = this.props.game
        const streamData = this.props.streamData

        const {gameTime, phase} = this.calculateGameTime(game)
        const sport = this.props.type

        if (game) {
            const sportsIcon = require(`./../assets/${sport}.svg`)
            return (
                <Ref as={Grid} innerRef={this.toggleRef} className={''} columns={3} divided stackable>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <div className={'column-content'}>
                                <img src={sportsIcon} alt="" className={`sport-icon`}/>
                                <div className={'stadium'}><img src={pitch} alt="" className={`pitch-icon pitch-icon-${sport}`}/><span>{game.StadiumName}</span></div>
                                <div className={'game-clock'}>
                                    <div className={'clock'}>
                                        <img src={timer} alt="" className="game-time"/>
                                        <div className={'phase'}>{phase}</div>
                                    </div>
                                    <div className={'time-phase'}>
                                        <div className={'time'}>{gameTime}</div>
                                    </div>
                                </div>
                                <div className={'data-holder'} ref={this.frameRef}><span className={'data-label'}>Last frame received: </span><div className={'last-frame'}>{game.frames}</div></div>
                                {game.gen6 && streamData && <div className={'skeleton-frames data-holder'} ref={this.skeletonFrameRef}><span className={'data-label'}>Last skeleton frame: </span><div className={'last-frame'}>{streamData.frame_number}</div></div> }
                                {game.AIClicker === true &&
                                    <div className={'event-section'}>
                                        <div className={'data-holder'}>Status: <span>{game.clickerStatus ? game.clickerStatus.status : ''}</span></div>
                                        <div className={'data-holder'}>Event count: <span>{game.streamInfo ? game.streamInfo.count : ''}</span></div>
                                        <div className={'data-holder'}>Last clicker frame: <span>{game.streamInfo ? game.streamInfo.lastFrame : ''}</span></div>
                                        <div className={'data-holder'}>Last event: <span>{game.streamInfo ? new Date(parseInt(game.streamInfo.lastTime)).toLocaleString('en-GB', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }) : ''}</span></div>
                                    </div>
                                }
                            </div>
                        </Grid.Column>
                        <Grid.Column width={10} className={'game-content'}>
                            <div className={'kick-off'}>{game.Date ? game.Date.slice(0, -3) : ''}</div>
                            <Grid columns={3}>
                                <Grid.Row className={'teams'}>
                                    <Grid.Column width={7}>
                                        <Icon name='star' onClick={() => this.props.toggleStared(game.VendorID + '_' + game.GameID)} />
                                        <h4 className={'team'}>{game.HomeTeamName}</h4>
                                        <div className={'distance'}>Total distance: <span>{game.homeDistance} meters</span></div>
                                    </Grid.Column>
                                    <Grid.Column width={2}>
                                        -
                                    </Grid.Column>
                                    <Grid.Column width={7}>
                                    <h4 className={'team'}>{game.AwayTeamName}</h4>
                                        <div className={'distance'}>Total distance: <span>{game.awayDistance} meters</span></div>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            {
                                game.Extractions.map((ext, index) => {
                                    const extTime = new Date(ext.extraction_time)
                                    let extraction =
                                        <div className={'extraction'} key={game.GameID + '-' + ext.extraction_version}>
                                            <div className={'data-holder status'}>Extraction version {ext.extraction_version}: <span>{extTime.toLocaleString('en-GB', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })}</span>
                                            {!ext.error_message &&
                                                <span>. No extraction errors</span>
                                            }
                                            </div>
                                            <Container className={'error'}>{ext.error_message ? ext.error_message : ''}{ext.attempts > 0 ? ` (${ext.attempts} attempts)` : ''}</Container>
                                        </div>
                                    return extraction
                                })
                            }
                        </Grid.Column>
                        <Grid.Column className={'last-column'} width={3}>
                            <div className={'column-content'}>
                                <div className={'icon-wrapper'}>
                                    <Icon className={'collapse-icon'} name='caret down' onClick={this.toggleGame.bind(this)} />
                                </div>
                                <div className={'data-holder vendor-id'}>Vendor ID: <span className={'id-value'}>{game.VendorID}</span></div>
                                <div className={'data-holder game-id'}>Game ID: <span className={'id-value'}>{game.GameID}</span></div>
                                <div className={'data-holder dq'}>Data quality: <span className={'id-value'}>{game.DataQuality}</span></div>
                                <div className={'data-holder league-id'}>League ID: <span className={'id-value'}>{game.LeagueID}</span></div>
                                <div className={'data-holder season-id'}>Season ID: <span className={'id-value'}>{game.SeasonID}</span></div>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Ref>
            )
        }
    }
}
