import React, { Component } from "react";
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"


class Swagger extends Component {
  constructor(props) {
    super(props)

    this.state = {
      json: {}
    }
  }

  componentDidMount() {
    fetch('/api-doc', {
      accept: 'application/json'
    })
    .then(response => response.json())
    .then(res => {
      // console.log(res)
      this.setState({
        json: res
      })
    })
  }

  render()
  {
    return (<SwaggerUI spec={this.state.json} docExpansion="list"/>)
  }
}

export default Swagger