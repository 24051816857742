import React from 'react'
import './Heading.css'

function Heading(attr) {
  return (
    <div className="Page-heading">
      <h1>{ attr.title }</h1>
    </div>
  )
}

export default Heading
