import React, { Component } from "react"
import { Grid } from 'semantic-ui-react'

import { getTrackNameFromTrackID } from '../helpers'

export default class RaceDay extends Component {
    constructor(props) {
        super(props)
        this.state = {
            trackName: null
        }
    }

    componentDidMount() {
        this.setState({
            trackName : getTrackNameFromTrackID(this.props.track, this.props.vendor),
        })
    }

    render() {
        const sport = this.props.type
        const sportsIcon = require(`./../assets/${sport}-dark.svg`)
        const streamInfo = this.props.streamInfo

        return (
            <Grid className='race-day'>
                <Grid.Column floated='left' width={5}>
                    <img src={sportsIcon} alt="" className={`sport-icon`}/>
                    <h3>{this.state.trackName}</h3><span>, {this.props.date.split(' ')[0]}</span>
                </Grid.Column>
                <Grid.Column floated='right' width={5}>
                    <div className={'event-section'}>
                        <div className={'data-holder'}>Last event received: <div>{streamInfo ? new Date(parseInt(streamInfo.lastTime)).toLocaleString('en-GB', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }) : ''}</div></div>
                    </div>
                </Grid.Column>
            </Grid>
        )
    }
}
