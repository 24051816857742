import React, { Component } from 'react';
import { Loader, Dimmer, Grid, Segment, List, Statistic, Header, Button } from 'semantic-ui-react'
import './permissions/Permissions.css'


class Permissions extends Component {
    constructor(props) {
        super(props)

        this.state = {
            status: {},
            permissions: [],
            isLoading: false,

            searchValue        : '',
            searchIsLoading    : false,
            searchResults      : [],
            searchSource       : {
                "Data Quality": {
                    "name": "Data Quality",
                    "results": [
                        { title: 'Live' },
                        { title: 'Observed' }
                    ]
                },
                "Active": {
                    "name": "Active",
                    "results": [
                        { title: 'Active', value: true },
                        { title: 'Invalid', value: false }
                    ]
                }
            },
            filteredPermissions: [],

            // Permission group dropdown options
            Available_extractions : [],
            PG_users              : [],
            PG_tagOptions         : []
        }

        this.deletePermission = this.deletePermission.bind(this)
    }

    getStatus() {
        fetch('/api/admin/status', {
            accept: 'application/json',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('apikey')}`
            }
        })
        .then(response => response.json())
        .then(res => {
            console.log('LOADED')
            console.log(res)
            this.setState({status: res})
        })
    }
  
    componentDidMount() {
        this.getStatus()
        this.timer = setInterval(() => {
            this.getStatus()
        }, 5000)
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    deletePermission = (e, { pod_name }) => {
        
        // const { permissions } = this.state
        // const this_permission = _.find(permissions, ['_id', _id])
        // if (this_permission !== undefined) this_permission.isDeleting = true
        // console.log(pod_name)
        // this.setState({isLoading: true}, () => {
            fetch(`/api/admin/pods/${pod_name}`, {
                method: 'DELETE',
                accept: 'application/json',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('apikey')}`,
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(res => {
            //     if (res.n === 1)
            //     {
            //         // Deleted
            //         let { permissions, searchSource } = this.state
            //         // permissions.forEach((permission, index) => {
            //         //     if (permission._id === _id) delete permissions[index]; return true
            //         // })

            //         permissions = _.filter(permissions, function(p) { return p._id !== _id })
            //         console.log(permissions)

            //         searchSource['Permission Groups'] = {
            //             name   : 'Permission Groups',
            //             results: permissions.map((value, index) => {
            //                 return { title: value.Name }
            //             })
            //         }

            //         // console.log(searchSource)

                    // this.setState({
                    //     // permissions: permissions,
                    //     // searchSource: searchSource
                    //     isLoading: false
                    // })
            //     }
            })
        // })
    }
    
  
    render() {
        return (
        <>
            <Dimmer inverted className={this.state.isLoading ? 'active' : ''}>
                <Loader size='large' content='Loading'></Loader>
            </Dimmer>

            {/* <Segment className={this.state.isLoading ? 'loading': ''}> */}
            
            <Grid padded>

                {
                    'Extraction' in this.state.status &&
                    <Grid.Row>
                        {/* Computer Screens */}
                        <Grid.Column width={4} only='computer'>
                            <ContainerStatus title="Extraction Containers" Extraction={this.state.status.Extraction} deletePermission={this.deletePermission} />
                        </Grid.Column>
                        {/* Mobile and Tablet screens */}
                        <Grid.Column width={16} only='mobile tablet'>
                            <ContainerStatus title="Extraction Containers" Extraction={this.state.status.Extraction} deletePermission={this.deletePermission} />
                        </Grid.Column>
                    </Grid.Row>
                }

                {/* <Grid.Row columns={1}>
                    <Grid.Column>
                        <Segment>
                            <pre style={{ overflowX: 'auto' }}>
                            {JSON.stringify(this.state.status, null, 2)}
                            </pre>
                        </Segment>
                    </Grid.Column>
                </Grid.Row> */}
            </Grid>
                
            {/* </Segment> */}
        </>
      )
    }
}

function appendLeadingZero(n) {
    if (n <= 9) {
      return "0" + n;
    }
    return n
}

function ContainerStatus(props)
{
    let errors = 0
    props.Extraction.forEach(element => {
        if (new Date() - new Date(element.timestamp) > (3*60*1000))
        {
            element.error = true
            errors++
        }
    });
    return (
        <>
        <Header as="h2" attached="top" textAlign='left'>{props.title}</Header>
        <Segment attached>
            {/* <Statistic horizontal label='Extractors' value={props.Extraction.length} /> */}
            <Statistic.Group widths={errors === 0 ? "1" : "2"}>
                <Statistic color="green">
                    <Statistic.Value>{props.Extraction.length - errors}</Statistic.Value>
                    <Statistic.Label>Running</Statistic.Label>
                </Statistic>
                {
                    errors > 0 &&
                    <Statistic color="red">
                        <Statistic.Value>{errors}</Statistic.Value>
                        <Statistic.Label>Errors</Statistic.Label>
                    </Statistic>
                }
            </Statistic.Group>
            {/* <Divider /> */}
            {
                props.Extraction.length > 0 &&
                <List style={{marginTop: "35px"}} divided relaxed>
                    {
                        props.Extraction.map((extractor, index) => {
                            let date = new Date(extractor.timestamp)
                            let seconds = appendLeadingZero(date.getSeconds())
                            let minutes = appendLeadingZero(date.getMinutes())
                            let hours = appendLeadingZero(date.getHours())

                            return (
                                <List.Item>
                                    <List.Icon name='node js' size='large' verticalAlign='middle' />
                                    <List.Content>
                                        <List.Header style={{color: extractor.error ? 'red': 'black'}}>{`${extractor.component}`}</List.Header>
                                        <List.Description style={{color: extractor.error ? 'red': 'black'}}>{extractor.unique_id}</List.Description>
                                        <List.Description style={{color: extractor.error ? 'red': 'black'}}>{`Updated ${appendLeadingZero(date.getDate())}-${appendLeadingZero(date.getMonth()+1)}-${date.getFullYear()} ${hours}:${minutes}:${seconds}`}</List.Description>
                                        {
                                            extractor.game !== null &&
                                            <List.Description style={{color: 'blue'}}>{`Extracting - ${extractor.game.vendor_id}, ${extractor.game.game_id}`}</List.Description>
                                        }
                                        <Button pod_name={extractor.unique_id} onClick={props.deletePermission}>Delete</Button>
                                    </List.Content>
                                </List.Item>
                            )
                        })
                    }
                </List>
            }
        </Segment>
        </>
    )
}


export default Permissions
