import _ from 'lodash'
import React, { Component } from "react"
import { Grid, Container, Message, Dimmer, Loader } from 'semantic-ui-react'
import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify'
import { gameIDToTrotting } from '../helpers'
import io from 'socket.io-client'
import 'react-toastify/dist/ReactToastify.css'

import Heading from '../Components/Heading'
import Game from './Game'
import Race from './Race'
import RaceDay from './RaceDay'
import Filters from './Filters'

import './Dashboard.css'
import './Game.css'

class Dashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            live        : [],
            stared      : [],
            userEmail   : '',
            messages    : null,
            isLoading   : true,
            activeSports: ['soccer', 'trotting'],
            filter      : false,
            itemName    : '',
            streamData  : {}
        }

        toast.configure({
            autoClose: 8000,
            position: toast.POSITION.BOTTOM_RIGHT
        })

        this.socket =  io('/socket.io/dashboard', {
            auth: { token: `Bearer ${localStorage.getItem('apikey')}` },
            transports: ["websocket"]
            // secure: true
        })

        this.toggleStared = this.toggleStared.bind(this)
        this.filterControl = this.filterControl.bind(this)
    }

    componentDidMount() {
        this.socket.on('populate', (data) => {
            const filter = Object.keys(data).length > 1 ? true : false

            let itemName = this.state.itemName
            if ('trotting' in data && 'soccer' in data) itemName = 'games/race days'
            else if ('trotting' in data && !('soccer' in data)) itemName = 'race days'
            else itemName = 'games'

            // Handle trotting grouped by race day.
            let trotting = []
            const raceDays = _.groupBy(data.trotting, function(item) {
                const date = item.Date.split(' ')[0]
                const dates = date.split('-')
                return `${item.VendorID}-${item.TrackID}-${dates[1]}${dates[2]}`
            })
            const raceDaysArr = Object.entries(raceDays)
            raceDaysArr.forEach(raceDay => {
                const races =  _.orderBy(raceDay[1], ['RaceID'], 'desc')
                trotting.push({
                    'ID'      : raceDay[0],
                    'TrackID' : raceDay[0].split('-')[1],
                    'VendorID': raceDay[1][0].VendorID,
                    'Races'   : races,
                    'Date'    : raceDay[1][0].Date
                })
            })
            const sports = _.union(data.soccer, trotting)

            const sorted = _.orderBy(sports, ['Date'], 'desc')

            // Check for stared games
            const storedStared = localStorage.getItem('stared')
            const stared = JSON.parse(storedStared) ? JSON.parse(storedStared) : []

            this.setState({
                live: sports.length ? sorted : [],
                stared: stared,
                isLoading: false,
                filter: filter,
                itemName: itemName
            })
        })

        this.socket.on('update', (data) => {
            let live = this.state.live

            data.forEach(item => {
                // @TODO This is becomming messy. Would be good to refactor...
                if (item.VendorID >= 1000) {
                    const trotting = gameIDToTrotting(item.GameID)
                    const idx = live.findIndex((i => (i.ID === `${item.VendorID}-${trotting.track_id}-${trotting.date}`) ))
                    if (idx !== -1) {
                        // Handle events coming in per race day.
                        if ('Type' in item && item.Type === 'race_day') {
                            live[idx].streamInfo = item.streamInfo
                        }
                        else {
                            if ('Races' in live[idx]) {
                                const ridx = live[idx].Races.findIndex((i => (i.GameID === item.GameID && i.VendorID === item.VendorID) ))
                                if (live[idx].Races[ridx] !== undefined) live[idx].Races[ridx].frames = item.frames
                            }
                        }
                    }
                }
                else {
                    let idx = live.findIndex((i => (i.GameID === item.GameID && i.VendorID === item.VendorID) ))
                    if (item.Extractions) {
                        let exts = live[idx].Extractions
                        live[idx] = _.assign({}, live[idx], item)
                        item.Extractions.forEach(ext => {
                            if (exts.some(e => e.id === ext.id)) {
                                const eid = exts.findIndex((i => (i.id === ext.id)))
                                exts[eid] = ext
                            }
                        })
                        live[idx].Extractions = exts
                    } else {
                        live[idx] = _.assign({}, live[idx], item)
                    }
                    if ('gen6' in item) {
                        live[idx].gen6 = item.gen6
                    }
                }
            })
            this.setState({live: live})
        })

        this.socket.on('games:remove', (data) => {
            let live = this.state.live
            data.forEach(item => {
                _.remove(live, {VendorID: item.VendorID, GameID: item.GameID})
            })
            this.setState({live: live})
        })

        this.socket.on('games:new', (data) => {
            let live = this.state.live

            data.forEach(item => {
                if (item.VendorID >= 1000) {
                    // Check if race day already exists.
                    const trotting = gameIDToTrotting(item.GameID)
                    const ID = `${item.VendorID}-${trotting.track_id}-${trotting.date}`
                    const idx = live.findIndex((i => (i.ID === ID) ))
                    if (idx !== -1) {
                        // Add to existing race day.
                        live[idx].Races.unshift(item)
                    }
                    else {
                        // Create new race day.
                        live.unshift({
                            'ID'      : ID,
                            'TrackID' : item.TrackID,
                            'VendorID': item.VendorID,
                            'Races'   : [item],
                            'Date'    : item.Date
                        })
                    }
                }
                else {
                    // Check if game already exists, if so add extraction.
                    if (live.some(e => e.GameID === item.GameID && e.VendorID === item.VendorID)) {
                        const idx = live.findIndex((i => (i.GameID === item.GameID && i.VendorID === item.VendorID) ))
                        live[idx].Extractions.push(item.Extractions[0])
                    }
                    else {
                        live.unshift(item)
                    }
                }
                this.setState({live: live})
            })
        })

        this.socket.on('connect_error', (err) => {
            console.log('connect_error', err.message);
        })

        // listen for messages from the server in the joined room
        this.socket.on('stream-data', (data) => {
            let streamData = this.state.streamData
            const parsedData = JSON.parse(data)
            const gameId = ('TrackID' in parsedData) ? parsedData.TrackID : parsedData.game_id
            if (gameId) {
                streamData[`${gameId}`] = parsedData
                this.setState({streamData: streamData})
            }
        })
    }

    componentWillUnmount() {
        this.socket.close();
    }

    toggleStared(starID) {
        let stared = this.state.stared

        if (stared && stared.includes(starID)) {
            _.pull(stared, starID)
        } else {
            stared.push(starID)
        }
        this.setState({stared: stared})
        localStorage.setItem('stared', JSON.stringify(stared))
    }

    filterControl() {
        const active = localStorage.getItem('activeSports')
        this.setState({activeSports: active})
    }

    render() {
        let liveRendered = null
        let staredGamesRendered = null
        let stared = this.state.stared
        let messages = this.state.messages
        const token = jwt_decode(localStorage.getItem('jwt'))
        const games = this.state.live
        let live = games
        let liveCount = live ? live.length : 0

        let itemName = this.state.itemName
        if (liveCount === 1 && itemName === 'games/race days') itemName = 'game/race day'
        else if (liveCount === 1) itemName = itemName.substring(0, itemName.length - 1)

        let staredGames = []
        stared.forEach((item) => {
            const ids = item.split('_')
            const vendorId = parseInt(ids[0])
            const gameId = parseInt(ids[1])
            const idx = games.findIndex((i => (i.VendorID === vendorId && i.GameID === gameId)))
            if (idx >= 0) {
                staredGames.push(games[idx])
            }
        })

        if (live && live.length) {
            liveRendered = <Grid.Row className={'dashboard-games live'}>
                <div className={'games-content'}>
                {
                    live.map((game, index) => {
                        const type = 'TrackID' in game ? 'trotting' : 'soccer'
                        // Check for hidden games
                        const activeSports = localStorage.getItem('activeSports')
                        const active = activeSports !== null ? JSON.parse(activeSports) : this.state.activeSports
                        const sportClass = _.includes(active, type) ? `${type}-visible` : `${type}-hidden` 
                        if (!_.includes(stared, `${game.VendorID}_${game.GameID}`)) {
                            return (type === 'trotting') ?
                                <div className={sportClass} key={`${game.ID}.${type}`}>
                                    <RaceDay track={game.TrackID} vendor={game.VendorID} streamInfo={game.streamInfo} date={game.Date} type={type} key={`raceday.${game.ID}.${type}`} />
                                    { 'Races' in game &&
                                        game.Races.map((race, index) => {
                                            return <Race game={race} type={type} key={`race.${race.VendorID}.${race.TrackID}.${race.RaceID}`} toggleStared={this.toggleStared} />
                                        })
                                    }
                                </div>
                            : <div className={sportClass} key={`${game.VendorID}.${game.GameID}.${type}`}><Game className={sportClass} game={game} type={type} streamData={this.state.streamData[`${game.GameID}`]} key={game.VendorID + '.' + game.GameID} toggleStared={this.toggleStared} /></div>
                        }
                        else {
                            return null
                        }
                    })
                }
                </div>
            </Grid.Row>
        }

        if (staredGames.length) {
            staredGamesRendered = <Grid.Row className={'dashboard-games stared'}>
                <h2>Favorites</h2>
                <div className={'games-content'}>
                {
                    staredGames.map((game, index) => {
                        const type = 'TrackID' in game ? 'trotting' : 'soccer'
                        const activeSports = localStorage.getItem('activeSports')
                        const active = activeSports !== null ? JSON.parse(activeSports) : this.state.activeSports
                        const sportClass = _.includes(active, type) ? `${type}-visible` : `${type}-hidden`
                        if (game !== undefined) {
                            return <div className={sportClass} key={`${game.VendorID}.${game.GameID}.${type}`}>
                                <Game className={sportClass} game={game} type={type} key={game.VendorID + '.' + game.GameID} isOpen={true} toggleStared={this.toggleStared} streamData={this.state.streamData[`${game.GameID}`]} /></div>
                        }
                        else return null
                    })
                }
                </div>
            </Grid.Row>
        }

        if (!live) live = <Message>No current live games</Message>
        if (!messages) messages = <Container>No messages</Container>

        return (
            <>
                <Dimmer inverted className={this.state.isLoading ? 'active' : ''}>
                    <Loader size='large' content='Loading'></Loader>
                </Dimmer>

                <Heading title={'Logged in as: ' + token.user.email} />

                <Grid padded stackable>
                    <Grid.Column width={12}>
                        <h4>{`${liveCount} ${itemName} currently live`} </h4>
                        {this.state.filter === true &&
                            <Filters filterControl={this.filterControl} />
                        }
                        {staredGamesRendered}
                        {liveRendered}
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Container>
                            <h3>Messages</h3>
                            {messages}
                        </Container>
                    </Grid.Column>
                </Grid>
            </>
        )
    }
}

export default Dashboard
