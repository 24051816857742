export function gameIDToTrotting(GameID) {
    const track_id = String(GameID).slice(5, 7)
    const race_id = String(GameID).slice(-2)
    const month_day = String(GameID).slice(1, 5) 

    return {track_id: track_id, race_id: race_id, date: month_day}
}

export function getTrackNameFromTrackID(id, vid) {
    const tracks = {
        1001: {
            '36': 'Arvika',
            '08': 'Axevalla',
            '09': 'Bergsåker',
            '11': 'Boden',
            '12': 'Bollnäs', 
            '13': 'Dannero',
            '14': 'Eskilstuna',
            '15': 'Färjestad',
            '16': 'Gävle',
            '17': 'Hagmyren',
            '18': 'Halmstad',
            '37': 'Hoting',
            '07': 'Jägersro',
            '19': 'Kalmar',
            '44': 'Karlshamn',
            '21': 'Lindesberg',
            '38': 'Lycksele',
            '22': 'Mantorp',
            '39': 'Oviken',
            '23': 'Romme',
            '24': 'Rättvik',
            '25': 'Skellefteå',
            '05': 'Solvalla',
            '26': 'Solänget',
            '46': 'Tingsryd',
            '27': 'Umåker',
            '43': 'Vaggeryd',
            '28': 'Visby',
            '06': 'Åby',
            '29': 'Åmål',
            '31': 'Årjäng',
            '32': 'Örebro',
            '33': 'Östersund'
        }
    }

    return (vid in tracks) ? tracks[vid][id] : id        
}

const typeMap = {
    0: 'Live',
    1: 'Observed',
    2: 'Partial'
}

export function DQNameToValue(name) {
    // Flip typeMap
    const flippedMap = Object.keys(typeMap).reduce((ret, key) => {
        ret[typeMap[key]] = key
        return ret
    }, {})
    return name in flippedMap ? flippedMap[name] : new Error('No type in map')
}

export function DQValueToName(value) {
    return value in typeMap ? typeMap[value] : new Error('No type in map')
}
