import React, { Component } from "react"
import { Grid, Icon, Ref } from 'semantic-ui-react'

import { gameIDToTrotting } from '../helpers'

export default class Race extends Component {
    constructor(props) {
        super(props)
        this.state = {
            lastFrame: null,
            frameControl: null,
            isOpen: false
        }
        this.frameRef = React.createRef()
        this.toggleRef = React.createRef()
    }

    componentDidMount() {
        const exclass = this.props.isOpen ? 'open' : 'collapsed'
        const div = this.toggleRef.current
        div.className = `ui stackable divided three column grid game ${exclass} ${this.props.type}`
        const newState = {}
        if (this.state.lastFrame === null) newState.lastFrame = this.props.game.frames
        if (this.props.isOpen) newState.isOpen = true
        if (newState !== {}) this.setState(newState)
    }

    shouldComponentUpdate(nextProps, nextState) {
        // return (JSON.stringify(nextProps) === JSON.stringify(this.props)) ? false : true
        return (nextProps.game.frames === this.state.lastFrame) ? false : true
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.updateGame(snapshot)
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        return (prevProps.game.frames) ? prevProps.game.frames : null
    }

    componentWillUnmount() {
        clearTimeout(this.frameControlTimer)
    }

    async updateGame(prevFrames) {
        const game = this.props.game
        clearTimeout(this.frameControlTimer)

        // @TODO This comparison should work the same as in Game component.
        if ((this.state.lastFrame && parseInt(this.state.lastFrame) < parseInt(game.frames)) ||
            (!this.state.lastFrame && game.frames)) {
            const div = this.frameRef.current
            div.className = 'data-holder receiving'
            this.setState({
                lastFrame : game.frames,
            })
        }
        else this.frameControlTimer = setTimeout(() => this.frameControl(game.frames), 3000)
    }

    frameControl(current) {
        if (this.state.lastFrame === current) {
            const div = this.frameRef.current
            div.className = 'data-holder stopped'
        }
    }

    toggleGame = () => {
        const isOpen = this.state.isOpen
        const div = this.toggleRef.current
        div.className = `ui stackable divided three column grid game ${isOpen ? 'collapsed' : 'open'} ${this.props.type}`
        
        this.setState({
            isOpen: !isOpen
        })
    }

    render() {
        const game = this.props.game
        const trottingIDs = gameIDToTrotting(game.GameID)

        if (game) {
            return (
                <Ref as={Grid} innerRef={this.toggleRef} className={''} columns={3} divided stackable>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <div className={'column-content'}>
                                <div className={'data-holder'} ref={this.frameRef}><span className={'data-label'}>Last frame received: </span><div className={'last-frame'}>{game.frames}</div></div>
                            </div>
                        </Grid.Column>
                        <Grid.Column width={10} className={'game-content'}>
                            <Grid columns={3}>
                                <Grid.Row className={'teams'}>
                                    <Grid.Column width={16}>
                                        <h4 className={'team'}>{`Race ${trottingIDs.race_id}`}</h4>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column className={'last-column'} width={3}>
                            <div className={'column-content'}>
                                <div className={'icon-wrapper'}>
                                    <Icon className={'collapse-icon'} name='caret down' onClick={this.toggleGame.bind(this)} />
                                </div>
                                <div className={'data-holder vendor-id'}>Vendor ID: <span className={'id-value'}>{game.VendorID}</span></div>
                                <div className={'data-holder game-id'}>Game ID: <span className={'id-value'}>{game.GameID}</span></div>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Ref>
            )
        }
    }
}
