import React, { Component } from "react"
import _ from 'lodash'
import { Button } from 'semantic-ui-react'
import './Filters.css'

export default class Filters extends Component {
    constructor(props) {
        super(props)

        this.state = {
            sports: ['soccer', 'trotting'],
            activeSports: ['soccer', 'trotting']
        }
    }

    componentDidMount() {
        const activeSports = localStorage.getItem('activeSports')
        if (activeSports !== null) this.setState({activeSports: JSON.parse(activeSports)})
    }

    filterGames = (sport) => {
        const activeSports = localStorage.getItem('activeSports')
        const active = JSON.parse(activeSports) ? JSON.parse(activeSports) : []
        const sports = active.length ? active : this.state.activeSports
        if (sports.includes(sport)) {
            _.pull(sports, sport)
        } else {
            sports.push(sport)
        }
        this.setState({
            activeSports: sports
        })
        // @TODO
        localStorage.setItem('activeSports', JSON.stringify(sports))
    }

    render() {
        return (
            <div className={'game-filter'}>
                {
                    this.state.sports.map(sport => {
                        const icon = require(`./../assets/${sport}-large.svg`)
                        const active = _.includes(this.state.activeSports, sport) ? 'active' : 'inactive'
                        return <Button type='submit' onClick={() => {this.filterGames(sport); this.props.filterControl()}} className={`filter-button ${sport}-filter ${active}`} key={sport}>
                            <img src={icon} alt="" className={`sport-icon`}/>
                            <div>{sport}</div>
                            </Button>
                    })
                }
                
            </div>
        )
    }
}

