import React, { Component } from 'react';
import { Loader, Dimmer, Grid, Table, Checkbox, Search } from 'semantic-ui-react'
import _ from 'lodash'
import AddPermission from './AddPermissionsForm'
import './Permissions.css'


class Permissions extends Component {
    constructor(props) {
        super(props)

        this.state = {
            permissions: [],
            isLoading: true,

            searchValue        : '',
            searchIsLoading    : false,
            searchResults      : [],
            searchSource       : {
                "Data Quality": {
                    "name": "Data Quality",
                    "results": [
                        { title: 'Live' },
                        { title: 'Observed' },
                        { title: 'Partial' }
                    ]
                },
                "Active": {
                    "name": "Active",
                    "results": [
                        { title: 'Active', value: true },
                        { title: 'Invalid', value: false }
                    ]
                }
            },
            filteredPermissions: [],

            // Permission group dropdown options
            Available_extractions : [],
            PG_users              : [],
            PG_tagOptions         : []
        }


        // Permissions Form
        this.addPermission    = this.addPermission.bind(this)
        this.updatePermission = this.updatePermission.bind(this)


        // Permissions Table
        this.togglePermissionState = this.togglePermissionState.bind(this)

        this.deletePermission      = this.deletePermission.bind(this)

        // Permissions Table Search
        this.handleSearchChange = this.handleSearchChange.bind(this)
        this.handleResultSelect = this.handleResultSelect.bind(this)
    }



    componentDidMount() {
      fetch('/api/admin/permissions_page', {
        accept: 'application/json',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('apikey')}`
        }
      })
      .then(response => response.json())
      .then(res => {
        // console.log('LOADED')
        // console.log(res)

        const state = Object.assign({}, this.state)
        state.isLoading   = false
        res.permissions = res.permissions.map((value, index) => {
            value.title = value.Name
            return value
        })
        state.permissions = res.permissions
	    state.Available_extractions = res.extraction_versions
        state.PG_tagOptions = res.permissions_list
        state.PG_users    = res.users.map((value, index) => {
            return {
                key  : value._id,
                text : value.email,
                value: value.user_id,
                label: { color: value.valid ? 'green' : 'red', empty: true, circular: true }
            }
        })

        /**
         * Add Users to search
         */
        state.searchSource['Permission Groups'] = {
            name   : 'Permission Groups',
            results: res.permissions.map((value, index) => {
                return { title: value.Name }
            })
        }

        state.searchSource['Users'] = {
            name   : 'Users',
            results: res.users.map((value, index) => {
                return { title: value.email, description: `User ID: ${value.user_id}`, id: value.user_id }
            })
        }

        state.searchSource['Permission Items'] = {
            name: 'Permission Items',
            results: state.PG_tagOptions.map((value, index) => {
                return { title: value.text, value: value.value }
            })
        }

        this.setState(state)
      })
    }

    addPermission(data) {
        const permissions = this.state.permissions
        permissions.push(data)

        const searchSource = this.state.searchSource
        searchSource['Permission Groups'] = {
            name   : 'Permission Groups',
            results: permissions.map((value, index) => {
                return { title: value.Name }
            })
        }

        this.setState({
            permissions: permissions,
            searchSource: searchSource
        })
    }

    updatePermission(id, data) {
        console.log(data)
        const state = Object.assign({}, this.state)

        const this_permission              = _.find(state.permissions, ['_id', id])
        this_permission.Name               = data.Name
        this_permission.DataType           = data.DataType
        this_permission.Active             = data.Active
        this_permission.Officials          = data.Officials
        this_permission.Users              = data.Users
        this_permission.ExtractionVersions = data.ExtractionVersions
        this_permission.Outputs            = data.Outputs
        this_permission.Permissions        = data.Permissions
        this_permission.Start              = data.Start
        this_permission.Expire             = data.Expire

        state.searchSource['Permission Groups'] = {
            name   : 'Permission Groups',
            results: state.permissions.map((value, index) => {
                return { title: value.Name }
            })
        }

        this.setState(state)
    }

    deletePermission = (e, { _id }) => {

        const { permissions } = this.state
        const this_permission = _.find(permissions, ['_id', _id])
        if (this_permission !== undefined) this_permission.isDeleting = true

        this.setState({permissions}, () => {
            fetch(`/api/admin/permission/${_id}`, {
                method: 'DELETE',
                accept: 'application/json',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('apikey')}`,
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(res => {
                if (res.n === 1)
                {
                    // Deleted
                    let { permissions, searchSource } = this.state
                    // permissions.forEach((permission, index) => {
                    //     if (permission._id === _id) delete permissions[index]; return true
                    // })

                    permissions = _.filter(permissions, function(p) { return p._id !== _id })
                    console.log(permissions)

                    searchSource['Permission Groups'] = {
                        name   : 'Permission Groups',
                        results: permissions.map((value, index) => {
                            return { title: value.Name }
                        })
                    }

                    // console.log(searchSource)

                    this.setState({
                        permissions: permissions,
                        searchSource: searchSource
                    })
                }
            })
        })
    }

    togglePermissionState(e, { user_id, checked }) {
        // alert(`${user_id} ${checked}`)

        const { permissions } = this.state
        permissions.forEach((permission, index) => {
            if (permission._id === user_id) permission.Active = checked; return true
        })

        // this.setState({permissions}, () => {
        //     console.log(JSON.stringify({
        //         _id: user_id,
        //         state: String(checked)
        //     }))
        // })

        this.setState({permissions}, () => {
            // alert(JSON.stringify(user_id))
            fetch('/api/admin/permission_/toggle', {
                method: 'PUT',
                accept: 'application/json',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('apikey')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    _id: user_id,
                    state: String(checked)
                })
            })
            .then(response => response.json())
            .then(res => {
                // console.log('Updated')
                // console.log(res)
                // const users = Object.assign({}, this.state.users)
                // console.log(users)
                // users.push(res)
                // const users = this.state.users
                // users.push(res)
                // this.setState({
                //   users: users
                // })
            })
        })
    }

    handleResultSelect = (e, { result }) => {
        // console.log(result)
        // this.setState({ searchIsLoading: true, searchValue: value })

        // setTimeout(() => {
            // if (this.state.searchValue.length < 1) return this.setState({searchValue: '', searchIsLoading: false, searchResults: []})

            const re = new RegExp(_.escapeRegExp(result.title), 'i')
            const isMatch = (result_) => re.test(result_.title)

            const filteredResults = _.reduce(
                this.state.searchSource,
                (memo, data, name) => {
                    let results = []
                    // if (data.name == 'Permission Groups') results = _.filter(data.results, isMatch)
                    // if (data.name == 'Active') results = _.filter(data.results, ['Active', true])
                    // else results = _.filter(data.results, isMatch)
                    results = _.filter(data.results, isMatch)
                    if (results.length) memo[name] = { name, results } // eslint-disable-line no-param-reassign

                    return memo
                },
                {},
            )

            const resultsOfFilter = filteredResults

            let filteredPermissions = []

            // console.log(this.state.permissions)
            this.state.permissions.map((permission, index) => {
                let found = false
                _.forEach(resultsOfFilter, (value, key) => {
                    _.forEach(value.results, (result) => {
                        // Check the type of filter
                        if (key === 'Permission Groups' && permission.Name === result.title)
                        {
                            found = true
                            return false
                        }
                        else if (key === 'Users' && permission.Users.Individuals.includes(result.id))
                        {
                            found = true
                            return false
                        }
                        else if (key === 'Permission Items' && permission.Outputs.includes(result.value))
                        {
                            found = true
                            return false
                        }
                        else if (key === 'Active' && permission.Active === result.value)
                        {
                            found = true
                            return false
                        }
                        else if (key === 'Data Quality' && permission.DataType.includes(result.title))
                        {
                            found = true
                            return false
                        }
                    })

                    if (found === true) return false
                })
                if (found === true) filteredPermissions.push(permission)
                return null
            })

            this.setState({
                searchIsLoading    : false,
                searchResults      : filteredResults,
                filteredPermissions: filteredPermissions,
                searchValue        : result.title
                // searchResults: _.filter(this.state.permissions, isMatch),
            })
        // }, 300)
    }

    handleSearchChange = (e, { value }) => {
        this.setState({ searchIsLoading: true, searchValue: value })
        // console.log(value)
        setTimeout(() => {
            if (this.state.searchValue.length < 1) return this.setState({searchValue: '', searchIsLoading: false, searchResults: [], filteredPermissions: []})

            const re = new RegExp(_.escapeRegExp(this.state.searchValue), 'i')
            const isMatch = (result) => re.test(result.title)

            const filteredResults = _.reduce(
                this.state.searchSource,
                (memo, data, name) => {
                    let results = []
                    // if (data.name == 'Permission Groups') results = _.filter(data.results, isMatch)
                    results = _.filter(data.results, isMatch)
                    if (results.length) memo[name] = { name, results } // eslint-disable-line no-param-reassign

                    return memo
                },
                {},
            )

            const resultsOfFilter = filteredResults

            let filteredPermissions = []

            // console.log(this.state.permissions)
            this.state.permissions.map((permission, index) => {
                let found = false
                _.forEach(resultsOfFilter, (value, key) => {
                    _.forEach(value.results, (result) => {
                        // Check the type of filter
                        if (key === 'Permission Groups' && permission.Name === result.title)
                        {
                            found = true
                            return false
                        }
                        else if (key === 'Users' && permission.Users.Individuals.includes(result.id))
                        {
                            found = true
                            return false
                        }
                        else if (key === 'Permission Items' && permission.Outputs.includes(result.value))
                        {
                            found = true
                            return false
                        }
                        else if (key === 'Active' && permission.Active === result.value)
                        {
                            found = true
                            return false
                        }
                        else if (key === 'Data Quality' && permission.DataType.includes(result.title))
                        {
                            found = true
                            return false
                        }
                    })

                    if (found === true) return false
                })
                if (found === true) filteredPermissions.push(permission)
                return null
            })

            // console.log(filteredPermissions)

            this.setState({
                searchIsLoading: false,
                searchResults: filteredResults,
                filteredPermissions: filteredPermissions
                // searchResults: _.filter(this.state.permissions, isMatch),
            })
        }, 0)
    }


    render() {
        const { searchIsLoading, searchValue, searchResults, permissions } = this.state
        // const permissions_list = (searchValue !== '' && searchIsLoading === false) ? searchResults : permissions
        const permissions_list = (searchValue !== '' && searchIsLoading === false) || this.state.filteredPermissions.length > 0 ? this.state.filteredPermissions : permissions

        return (
        // <div>{JSON.stringify(this.state.permissions)}</div>
        <>
            <Dimmer inverted className={this.state.isLoading ? 'active' : ''}>
                <Loader size='large' content='Loading'></Loader>
            </Dimmer>

            {/* <Segment className={this.state.isLoading ? 'loading': ''}> */}

            <Grid padded>
                {/* <Grid.Row> */}
                    <Grid.Column width={4}>
                        {/* <Segment> */}
                        {/* <Header>Search</Header> */}
                        <Search
                            category
                            fluid={true}
                            input={{fluid: true}}
                            placeholder='Search'
                            loading={searchIsLoading}
                            onResultSelect={this.handleResultSelect}
                            onSearchChange={_.debounce(this.handleSearchChange, 500, {
                                leading: true,
                            })}
                            results={searchResults}
                            value={searchValue}
                            {...this.props}
                            />
                        {/* </Segment> */}
                    </Grid.Column>
                    <Grid.Column width={2}><AddPermission
                        // Options
                        // edit      = {this.state.PG_edit}
                        // isSending = {this.state.PG_isSending}
                        // warnings  = {this.state.PG_warnings}
                        // Data properties
                        key                  = {Math.random()}
                        isLoading            = {this.state.PG_isLoading}
                        users                = {this.state.PG_users}
                        tagOptions           = {this.state.PG_tagOptions}
                        availableExtractions = {this.state.Available_extractions}
                        addPermission        = {this.addPermission}
                        handleUpdate         = {this.handleUpdate}
                    /></Grid.Column>
                    {/* <Grid.Column width={8}>
                        <Segment>
                            <pre style={{ overflowX: 'auto' }}>
                            {JSON.stringify(this.state.searchResults, null, 2)}
                            </pre>
                        </Segment>
                    </Grid.Column> */}
                {/* </Grid.Row> */}

                <Grid.Row columns={1}>
                <Grid.Column>
                    {/* <div style={{overflowX: 'scroll', height: 600}}> */}
                <Table compact selectable key='blue'>
                    <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell/>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>ID</Table.HeaderCell>
                        <Table.HeaderCell>Start</Table.HeaderCell>
                        <Table.HeaderCell>Expire</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                        {/* <Table.HeaderCell></Table.HeaderCell> */}
                        {/* <Table.HeaderCell textAlign='center'>Start / Stop</Table.HeaderCell> */}
                    </Table.Row>
                    </Table.Header>

                    <Table.Body>

                    {
                        permissions_list.map((bout, index) => {
                            return <PermissionRow
                                togglePermissionState={this.togglePermissionState}
                                bout={bout}
                                index={index}
                                deletePermission={this.deletePermission}
                                availableUsers={this.state.PG_users}
                                availableEVs={this.state.Available_extractions}
                                availablePermItems={this.state.PG_tagOptions}
                                updatePermission={this.updatePermission}
                                key={index}
                            />
                        })
                    }
                    </Table.Body>
                </Table>
                {/* </div> */}
                </Grid.Column>
                </Grid.Row>
            </Grid>

            {/* </Segment> */}
        </>
      )
    }
}

function PermissionRow(props) {
    // alert(props.bout.valid)
    // console.log(new Date(props.bout.Start))
    // console.log(new Date())

    console.log(new Date() > new Date(props.bout.Start))

    const startDateValid = new Date() > new Date(props.bout.Start)
    const expiryDateValid = new Date() < new Date(props.bout.Expire)

    // const active = props.bout.Active ? 'positive' : 'negative'
    let active = 'positive'
    if (props.bout.Active === false || startDateValid === false || expiryDateValid === false) active = 'negative'


    return (
        <Table.Row index={'props.bout.index'} className={active}>
        {/* <Table.Row index={'props.bout.index'} warning> */}
            <Table.Cell collapsing>
                <Checkbox slider checked={props.bout.Active} user_id={props.bout._id} onChange={props.togglePermissionState} />
            </Table.Cell>
            <Table.Cell>{props.bout.Name}</Table.Cell>
            <Table.Cell>{props.bout._id}</Table.Cell>
            <Table.Cell style={{fontWeight: startDateValid ? 'normal' : 'bold'}}>{props.bout.Start.substring(0, 10)}</Table.Cell>
            <Table.Cell style={{fontWeight: expiryDateValid ? 'normal' : 'bold'}}>{props.bout.Expire.substring(0, 10)}</Table.Cell>
            {/* <Table.Cell><Button content='Edit' color='blue' _id={props.bout._id} onClick={props.handleEditGroup}/></Table.Cell> */}
            <Table.Cell><AddPermission
                // Options
                users                = {props.availableUsers}
                availableExtractions = {props.availableEVs}
                tagOptions           = {props.availablePermItems}
                updatePermission = {props.updatePermission}

                edit = {true}
                // Data properties
                key                  = {Math.random()} // This will force the form to update if something changes to the row, such as toggling valid state
                id                   = {props.bout._id}
                name                 = {props.bout.Name}
                live                 = {props.bout.DataType.includes('Live')}
                observed             = {props.bout.DataType.includes('Observed')}
                partial              = {props.bout.DataType.includes('Partial')}
                active               = {props.bout.Active}
                officials            = {'Officials' in props.bout ? props.bout.Officials : false}
                datesRange           = {`${props.bout.Start.substring(0, 10)} - ${props.bout.Expire.substring(0, 10)}`}
                // gameFilter           = {props.bout.Permissions}
                gameFilter           = {_.map(props.bout.Permissions, (game) => {
                    return {
                        VendorID: String(game.VendorID),
                        leagues : 'Leagues' in game ? game.Leagues.join(',') : '',
                        seasons : 'Seasons' in game ? game.Seasons.join(',') : '',
                        teams   : 'Teams' in game ? game.Teams.join(',')     : '',
                        games   : 'Games' in game ? game.Games.join(',')     : '',
                    }
                })}
                dropDownValues       = {props.bout.Outputs}
                dropDownUsersValues  = {props.bout.Users.Individuals}
                Selected_EVs         = {props.bout.ExtractionVersions}

            /></Table.Cell>
            {/* <Table.Cell><Button className={props.bout.isDeleting === true ? 'loading': ''} content='Delete' color='red' _id={props.bout._id} onClick={props.deletePermission}/></Table.Cell> */}
        </Table.Row>
    )
}


export default Permissions
